import { httpClient } from "../../../../../utils/HttpClient"

const getUserManualBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`user_manual/get-user-manual-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertUserManualFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`user_manual/insert-user-manual`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateUserManualByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`user_manual/update-user-manual-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteUserManualByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`user_manual/delete-user-manual-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getUserManualBySearchFetch,

    // insert
    insertUserManualFetch,

    // update
    updateUserManualByIdFetch,

    // delete
    deleteUserManualByIdFetch
}