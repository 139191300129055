/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import parse from 'html-react-parser'
import { 
    getKhunHorBySearchFetch,
    insertKhunHorFetch,
    updateKhunHorByIdFetch,
    deleteKhunHorByIdFetch  
} from './API'

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'title',
        dataIndex: 'title',
        width: "10%",
    },
    {
        title: 'detail',
        dataIndex: 'detail',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function KhunHor() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)

    const audioReboundRef = useRef()


    // -------------------------------------------------------------------
    const [detail, setDetail] = useState()
    const detailRef = useRef()
    // -------------------------------------------------------------------

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const getMandalaSearch = async (objSearch) => {
        setLoading(true)

        // console.log("getKhunHorBySearchFetch : ", objSearch)
        const result = await getKhunHorBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                // subTitle: val.subTitle,
                detail: parse(val.detail),
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <div style={{ paddingBottom: 6 }}>
                            <Button
                                style={{
                                    width: 35,
                                    backgroundColor: "orange",
                                    border: "1px solid orange",
                                    color: 'white',
                                    borderRadius: 50
                                }}
                                onClick={() => {
                                    audioReboundRef.current.play()
                                    form.setFieldsValue({
                                        khunHorId: val.khunHorId,
                                        title: val.title,
                                        // subTitle: val.subTitle,
                                        detail: val.detail,
                                    })

                                    setDetail(result?.detail ?? "")
                                    detailRef.current = result?.detail ?? ""

                                    setModal({ isShow: true, title: "edit" })
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </div>{"  "}

                        <div style={{ paddingBottom: 0 }}>
                            <Popconfirm
                                title="คุณยืนยันลบหรือไม่ ?"
                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                onConfirm={async () => {
                                    await handleMandalaDelete(val.knowledgeSourceId)

                                    // reload
                                    await getMandalaSearch({
                                        hierarchy: null,
                                        lexicon: null,
                                        key_word: null,
                                        e_factors: null,
                                        factors: null,
                                        equation: null,
                                        meaning: null
                                    })
                                }}
                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                            >
                                <Button
                                    danger
                                    type="primary"
                                    style={{
                                        width: 35,
                                        borderRadius: 50
                                    }}
                                    onClick={() => audioReboundRef.current.play()}
                                >
                                    <div style={{ marginTop: 0, marginLeft: -8 }}>
                                        <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                    </div>
                                </Button>
                            </Popconfirm>
                        </div>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            khunHorId: values.khunHorId ? values.khunHorId : null,
            title: values.title ? values.title : null,
            // subTitle: values.subTitle ? values.subTitle : null,
            detail: detailRef.current ? detailRef.current : null,
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertKhunHorFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateKhunHorByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getMandalaSearch({
            hierarchy: null,
            lexicon: null,
            key_word: null,
            e_factors: null,
            factors: null,
            equation: null,
            meaning: null
        })

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let objSearch = {
            title: values.title ? values.title : null,
            // subTitle: values.subTitle ? values.subTitle : null,
            detail: values.detail ? values.detail : null
        }
        // console.log("onSearchFinish : ", objSearch)
        await getMandalaSearch(objSearch)
    }

    const handleMandalaDelete = async (id) => {
        const result = await deleteKhunHorByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getMandalaSearch({
            hierarchy: null,
            lexicon: null,
            key_word: null,
            e_factors: null,
            factors: null,
            equation: null,
            meaning: null
        })
    }, [])

    return (
        <Row>
            <Col span={24}>
                <Row style={{ borderBottom: "1px solid gray" }}>
                    <Col span={12}>
                        <label>ข้อมูลขุนโหร</label>
                    </Col>

                    <Col span={12} style={{ paddingBottom: 20 }}>
                        <Button
                            type="primary"
                            style={{ float: 'right' }}
                            onClick={() => {
                                audioReboundRef.current.play()
                                form.resetFields()
                                setModal({
                                    isShow: true,
                                    title: "add"
                                })
                            }}
                        >เพิ่มรายการ</Button>
                    </Col>
                </Row>
            </Col>

            <Col span={24} style={{ paddingBottom: 12, paddingTop: 12 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8} xl={8}>
                            <Form.Item
                                label="หัวข้อคำทำนาย"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={14} xl={14}>
                            <Form.Item
                                label="รายละเอียดคำทำนาย"
                                name="detail"
                                style={{ width: '100%' }}
                            >
                                <Input.TextArea
                                    style={{ width: "100%" }}
                                    autoSize={{ minRows: 6, maxRows: 10 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={2} xl={2}>
                            <div style={{ marginTop: 31 }}>
                                <Button
                                    style={{ float: 'right', width: "100%" }}
                                    type="primary"
                                    onClick={() => {
                                        audioReboundRef.current.play()
                                        formSearch.submit()
                                    }}
                                >
                                    ค้นหา
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={800}
                onOk={() => {
                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="khunHorId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="หัวข้อคำทำนาย"
                            // rules={[{ required: true, message: 'กรุณากรอก' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="detail"
                                label="รายละเอียดคำทำนาย"
                            // rules={[{ required: true, message: 'กรุณากรอก' }]}
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            // height: 350,
                                        },
                                    }}
                                    data={detail}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        detailRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </Row>
    )
}
