import { httpClient } from "../../../../../utils/HttpClient"

const getPlanetaryAspectBySearchFetch = async (value) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`planetary_aspect/get-planetary-aspect-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertPlanetaryAspectFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`planetary_aspect/insert-planetary-aspect-order`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePlanetaryAspectByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`planetary_aspect/update-planetary-aspect-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deletePlanetaryAspectByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`planetary_aspect/delete-planetary-aspect-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}


export {
    // get
    getPlanetaryAspectBySearchFetch,

    // insert
    insertPlanetaryAspectFetch,

    // update
    updatePlanetaryAspectByIdFetch,

    // 
    deletePlanetaryAspectByIdFetch,
}