import { httpClient } from "../../../../../utils/HttpClient"

const getServiceUsBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`service_us/get-service-us-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertServiceUsFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`service_us/insert-service-us`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateServiceUsByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`service_us/update-service-us-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteServiceUsByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`service_us/delete-service-us-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getServiceUsBySearchFetch,

    // insert
    insertServiceUsFetch,

    // update
    updateServiceUsByIdFetch,

    // delete
    deleteServiceUsByIdFetch,
}