import { httpClient } from "../../../../../utils/HttpClient"

const getKhunHorBySearchFetch = async (value) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`khun_hor/get-khun-hor-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertKhunHorFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`khun_hor/insert-khun-hor-order`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateKhunHorByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`khun_hor/update-khun-hor-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteKhunHorByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`khun_hor/delete-khun-hor-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}


export {
    // get
    getKhunHorBySearchFetch,

    // insert
    insertKhunHorFetch,

    // update
    updateKhunHorByIdFetch,

    // 
    deleteKhunHorByIdFetch,
}