/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Upload,
} from 'antd'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import {
    getPageHomeFetch,
    updatePageHomeByIdFetch
} from '../../../home/API'
import { Notification } from '../../../../common/components/notification'
import LoadingPage from '../../../../common/components/loading/page'

export default function HomePage() {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState(null)

    const [detail, setDetail] = useState()
    const detailRef = useRef()

    const [imageUrl, setImageUrl] = useState({
        loading: false,
        url: null
    })

    const optionImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "page_home/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImageUrl({
                        url: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setImageUrl({
                    loading: true,
                    url: imageUrl.url
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getAboutUs = async () => {
        const result = await getPageHomeFetch()

        setDetail(result?.detail ?? "")
        detailRef.current = result?.detail ?? ""

        setImageUrl({
            loading: false,
            url: result.imageUrl
        })

        setData(result)
    }

    console.log("imageUrl --- : ", imageUrl)
    const onFinish = async (values) => {
        let obj = {
            pageHomeId: values.pageHomeId ? values.pageHomeId : null,
            title: values.title ? values.title : null,
            subTitle: values.subTitle ? values.subTitle : null,
            detail: detailRef.current ? detailRef.current : null,
            imageUrl: imageUrl.url ? imageUrl.url : null,
        }
        console.log("obj : ", obj)
        const result = await updatePageHomeByIdFetch(obj)
        if (result.isSuccess) {
            Notification('success', 'เเก้ไขสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getAboutUs()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <Row>
                    <Col span={24} style={{ paddingBottom: 20 }}>
                        <label>เพจ (หน้าหลัก)</label>
                    </Col>
                    <Col xs={24} md={24} xl={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                pageHomeId: data?.pageHomeId,
                                title: data?.title,
                                subTitle: data?.subTitle
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item name="pageHomeId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Title"
                                        name="title"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Sub Title"
                                        name="subTitle"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <label>Detail</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript',
                                                // height: 350,
                                            },
                                        }}
                                        data={detail}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            detailRef.current = data
                                        }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div style={{ display: "grid" }}>
                                        {imageUrl?.url ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                                src={`${serverUrl}/${imageUrl.url}`}
                                            />
                                            :
                                            <img
                                                style={{ width: 207, borderRadius: 8 }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageUrl.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            style={{ float: 'right', width: 70 }}
                                            type="primary"
                                            onClick={() => {
                                                form.submit()
                                            }}
                                        >
                                            บันทึก
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                :
                <LoadingPage loading={loading} />
            }
        </>
    )
}
