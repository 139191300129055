import "./styles/App.css"
import "./styles/App.less"

import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import themeAntd from './styles/themeAntd'
import LoginERP from "./modules/erp/login"
import ERP from "./modules/erp"

const App = () => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <Router>
            <Routes>
                <Route exact path="/login" Component={() => (<LoginERP />)} />
                <Route exact path="/" Component={ERP} />
                <Route path="/:id">Page not found!</Route>
            </Routes>
        </Router>
    </ConfigProvider>
)

export default App
