import { httpClient } from "./../../../utils/HttpClient"

const signInAdminByIdFetch = async (username, password) => { // Done
    try {
        const result = await httpClient.post("erp/sign-in-admin-by-id", { username, password })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get

    // insert

    // update

    // delete

    signInAdminByIdFetch,
}