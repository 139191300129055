import { httpClient } from "../../../../../utils/HttpClient"

const getBlogAllFetch = async () => {
    try {
        const result = await httpClient.get(`blog/get-blog-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBlogBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`blog/get-blog-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertBlogFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`blog/insert-blog`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateBlogByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`blog/update-blog-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteBlogByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`blog/delete-blog-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getBlogAllFetch,
    getBlogBySearchFetch,

    // insert
    insertBlogFetch,
    
    // update
    updateBlogByIdFetch,

    // delete
    deleteBlogByIdFetch
}