import { httpClient } from "../../../../../utils/HttpClient"

const getMandalaBySearchFetch = async (value) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`mandala/get-mandala-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertMandalaFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`mandala/insert-mandala-order`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateMandalaByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`mandala/update-mandala-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteMandalaByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`mandala/delete-mandala-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getMandalaBySearchFetch,

    // insert
    insertMandalaFetch,

    // update
    updateMandalaByIdFetch,

    // delete
    deleteMandalaByIdFetch
}