/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Upload
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { apiServerUrl, serverUrl } from '../../../../constants'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { 
    getBookInfoBySearchFetch, 
    insertBookInfoFetch, 
    updateBookInfoByIdFetch,
    deleteBookInfoByIdFetch   
} from './API'
import parse from 'html-react-parser'

const { TextArea } = Input;

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'หัวข้อในเล่มหนังสือ',
        dataIndex: 'title',
        width: "20%",
    },
    {
        title: 'รายละเอียด',
        dataIndex: 'detail',
        width: "30%",
        render: (text, record) => {
            return (
                <label>{parse(record.detail)}</label>
            )
        }
    },
    {
        title: 'บทที่',
        dataIndex: 'chapter',
        width: "15%",
    },
    {
        title: 'เลขเพจ',
        dataIndex: 'pageNumber',
        width: "10%",
    },
    {
        title: 'รหัสเล่ม',
        dataIndex: 'bookCode',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function BookInfo() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const audioReboundRef = useRef()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [imgBookInfo, setBookInfo] = useState({
        loading: false,
        imageUrl: null
    })

    const [detail, setDetail] = useState("")
    const detailRef = useRef("")

    const optionBookInfo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "book_info/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setBookInfo({
                        imageUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setBookInfo({
                    loading: true,
                    imageUrl: imgBookInfo.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getBookInfoBySearch = async (objSearch) => {
        setLoading(true)

        // console.log("getBookInfoBySearchFetch : ", objSearch)
        const result = await getBookInfoBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                detail: val.detail,
                chapter: val.chapter,
                bookCode: val.bookCode,
                pageNumber: val.pageNumber,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <div style={{ paddingBottom: 6 }}>
                            <Button
                                style={{
                                    width: 35,
                                    backgroundColor: "orange",
                                    border: "1px solid orange",
                                    color: 'white',
                                    borderRadius: 50
                                }}
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    form.setFieldsValue({
                                        bookInfoId: val.bookInfoId,
                                        title: val.title,
                                        chapter: val.chapter,
                                        bookCode: val.bookCode,
                                        pageNumber: val.pageNumber,
                                    })

                                    setBookInfo({
                                        imageUrl: val.imageURL,
                                        loading: false,
                                    })

                                    setDetail(val.detail ?? "")
                                    detailRef.current = val.detail ?? ""

                                    setModal({ isShow: true, title: "edit" })
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </div>{"  "}

                        <div style={{ paddingBottom: 0 }}>
                            <Popconfirm
                                title="คุณยืนยันลบหรือไม่ ?"
                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                onConfirm={async () => {
                                    await handleBookInfoDelete(val.bookInfoId)

                                    // reload
                                    await getBookInfoBySearch({
                                        title: null,
                                        chapter: null,
                                        bookCode: null,
                                        pageNumber: null
                                    })
                                }}
                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                            >
                                <Button
                                    danger
                                    type="primary"
                                    style={{
                                        width: 35,
                                        borderRadius: 50
                                    }}
                                    onClick={() => audioReboundRef?.current?.play()}
                                >
                                    <div style={{ marginTop: 0, marginLeft: -8 }}>
                                        <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                    </div>
                                </Button>
                            </Popconfirm>
                        </div>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            bookInfoId: values?.bookInfoId ? values.bookInfoId : null,
            title: values?.title ? values.title : null,
            detail: detailRef.current ? detailRef.current : null,
            chapter: values?.chapter ? values.chapter : null,
            bookCode: values?.bookCode ? values.bookCode : null,
            pageNumber: values?.pageNumber ? values.pageNumber : null,
            imageURL: imgBookInfo?.imageUrl ? imgBookInfo.imageUrl : null
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertBookInfoFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateBookInfoByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getBookInfoBySearch({
            title: null,
            chapter: null,
            bookCode: null,
            pageNumber: null
        })

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let objSearch = {
            title: values?.title ? values.title : null,
            detail: values?.detail ? values.detail : null,
            chapter: values?.chapter ? values.chapter : null,
            bookCode: values?.bookCode ? values.bookCode : null,
            pageNumber: values?.pageNumber ? values.pageNumber : null,
        }
        // console.log("onSearchFinish : ", objSearch)
        await getBookInfoBySearch(objSearch)
    }

    const handleBookInfoDelete = async (id) => {
        const result = await deleteBookInfoByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setBookInfo({
            imageUrl: null,
            loading: false,
        })

        setDetail(null)
        detailRef.current = null

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getBookInfoBySearch({
            title: null,
            chapter: null,
            bookCode: null,
            pageNumber: null
        })
    }, [])

    return (
        <Row>
            <Col span={24}>
                <Row style={{ borderBottom: "1px solid gray" }}>
                    <Col span={12}>
                        <label>จัดการ Loading page</label>
                    </Col>

                    <Col span={12} style={{ paddingBottom: 20 }}>
                        <Button
                            type="primary"
                            style={{ float: 'right' }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                form.resetFields()
                                setModal({
                                    isShow: true,
                                    title: "add"
                                })
                            }}
                        >เพิ่มรายการ</Button>
                    </Col>
                </Row>
            </Col>

            <Col span={24} style={{ paddingBottom: 12, paddingTop: 12 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="หัวข้อในเล่มหนังสือ"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={18}>
                            <Form.Item
                                label="รายละเอียด"
                                name="detail"
                                style={{ width: '100%' }}
                            >
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="บทที่"
                                name="chapter"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="เลขเพจ"
                                name="pageNumber"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="รหัสในเล่มหนังสือ"
                                name="bookCode"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <div style={{ marginTop: 38 }}>
                                <Button
                                    style={{ float: 'right', width: "100%" }}
                                    type="primary"
                                    onClick={() => {
                                        audioReboundRef?.current?.play()
                                        formSearch.submit()}}
                                >
                                    ค้นหา
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={500}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="bookInfoId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="หัวข้อในเล่มหนังสือ"
                            // rules={[{ required: true, message: 'กรุณากรอก hierarchy' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="detail"
                                label="รายละเอียด"
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            height: 350,
                                        },
                                    }}
                                    data={detail}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        detailRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="chapter"
                                label="บทที่"
                            // rules={[{ required: true, message: 'กรุณากรอก e_factors' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="pageNumber"
                                label="เลขเพจ"
                            // rules={[{ required: true, message: 'กรุณากรอก factors' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="bookCode"
                                label="รหัสในเล่มหนังสือ"
                            // rules={[{ required: true, message: 'กรุณากรอก equation' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>รูปภาพประกอบ</label>
                                {imgBookInfo?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imgBookInfo.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: 207, borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionBookInfo}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imgBookInfo.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            onClick={() => audioReboundRef?.current?.play()}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
