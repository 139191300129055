/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
    getReviewBySearchFetch,
    insertReviewFetch,
    updateReviewByIdFetch,
    deleteReviewByIdFetch
} from './API'
import parse from 'html-react-parser'

const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'รีวิว',
        dataIndex: 'massage',
        width: "35%",
        render: (text, record) => {
            return (
                <label>{parse(record.massage)}</label>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: 'สถานะ',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    {record.isApprovalPublic ?
                        <label>เเสดงสู่สาธารณะ</label>
                        :
                        <label>ปิดการเเสดง</label>
                    }
                </>
            )
        }
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%"
    }
]

const formatDate = "DD/MM/YYYY"

export default function ReviewInspect() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    // field external
    const [massage, setMassage] = useState("")
    const massageRef = useRef("")

    const getReviewBySearch = async (massage, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            massage, startedDate, endDate
        }
        // console.log("getReviewBySearch : ", objSearch)
        const result = await getReviewBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                massage: val.massage,
                isApprovalPublic: val.isApprovalPublic,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                form.setFieldsValue({
                                    reviewId: val.reviewId,
                                    isApprovalPublic: val.isApprovalPublic,
                                    uid: val.uid
                                })

                                setMassage(val.massage ?? "")
                                massageRef.current = val.massage ?? ""

                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleReviewDelete(val.reviewId)

                                // reload
                                await getReviewBySearch(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            reviewId: values.reviewId ? values.reviewId : null,
            uid: values.uid ? values.uid : null,
            massage: massageRef.current ? massageRef.current : null,
            isApprovalPublic: false
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertReviewFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateReviewByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getReviewBySearch(null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let massage = values?.massage ? values.massage : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        await getReviewBySearch(massage, startedDate, endDate)
    }

    const handleReviewDelete = async (id) => {
        const result = await deleteReviewByIdFetch(id)
        // console.log("result : ", result, id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setMassage("")

        massageRef.current = ""

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getReviewBySearch(null, null, null)
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการสิ่งที่ผู้ใช้กล่าวถึงเรา</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อประกาศ"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    formSearch.submit()}}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        form.resetFields()
                        setMassage("")
                        massageRef.current = ""

                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={600}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="reviewId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="uid"
                                label="ไอดีของผู้ใช้"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="massage"
                                label="สิ่งที่ผู้ใช้กล่าวถึงเรา"
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            // height: 350,
                                        },
                                    }}
                                    data={massage}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        massageRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="isApprovalPublic"
                                label="เเสดงออกสู่สาธารณะหรือไม่ ?"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>ใช่</Option>
                                    <Option key={1} value={false}>ไม่ใช่</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
