import { httpClient } from "../../../../../utils/HttpClient"

const getBookInfoBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`book_info/get-book-info-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertBookInfoFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`book_info/insert-book-info`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateBookInfoByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`book_info/update-book-info-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteBookInfoByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`book_info/delete-book-info-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getBookInfoBySearchFetch,

    // insert
    insertBookInfoFetch,

    // update
    updateBookInfoByIdFetch,

    // delete
    deleteBookInfoByIdFetch,
}