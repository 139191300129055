/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Input,
    DatePicker,
    Popconfirm,
    Upload,
    InputNumber,
    Select
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { 
    // deletePredictionByIdFetch, 
    getPredictionBySearchFetch, 
    insertPredictionFetch, 
    updatePredictionByIdFetch 
} from './API'
import Video from '../../../../common/components/video'

const { Option } = Select
const { RangePicker } = DatePicker

const columnsPrediction = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ภาพปก',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    <img
                        style={{ borderRadius: 8, maxHeight: 70 }}
                        src={`${serverUrl}/${record.imgCoverUrl}`}
                    />
                </>
            )
        }
    },
    {
        title: 'ชื่อพยากรณ์',
        dataIndex: 'title',
        width: "30%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "15%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function PredictionManage() {

    const [predictionList, setPredictionList] = useState([])
    const [predictionLoading, setPredictionLoading] = useState(true)

    const [formPrediction] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [imgCoverPredictionURL, setImgCoverPredictionURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [vdoCourseURL, setVdoCourseURL] = useState({
        loading: false,
        videoUrl: null
    })

    // const [subTitle, setSubTitle] = useState()
    const [detail, setDetail] = useState()
    const [promotion, setPromotion] = useState()

    // const subTitleRef = useRef()
    const detailRef = useRef()
    const promotionRef = useRef()

    const audioReboundRef = useRef()


    const [predictionIdSelected, setPredictionIdSelected] = useState(null)

    const [pagePredictionCurrent, setPagePredictionCurrent] = useState(1)

    const [operation, setOperation] = useState({
        type: null,
        isOpen: false
    })

    const optionCoverPredictionImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "prediction/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgCoverPredictionURL({
                        imageUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setImgCoverPredictionURL({
                    loading: true,
                    imageUrl: imgCoverPredictionURL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionCourseVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "prediction/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setVdoCourseURL({
                        videoUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setVdoCourseURL({
                    loading: true,
                    videoUrl: vdoCourseURL.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getPredictionBySearch = async (title, startedDate, endDate) => {
        setPredictionLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getPredictionBySearchFetch : ", objSearch)
        const result = await getPredictionBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                imgCoverUrl: val.imgCoverUrl,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                audioReboundRef.current.play()
                                formPrediction.setFieldsValue({
                                    predictionId: val.predictionId,
                                    title: val.title,
                                    subTitle: val.subTitle,
                                    price: val.price,
                                    isCoverTypeDisplay: val.isCoverTypeDisplay
                                })

                                setImgCoverPredictionURL({
                                    loading: false,
                                    imageUrl: val.imgCoverUrl
                                })

                                setVdoCourseURL({
                                    loading: false,
                                    videoUrl: val.videoUrl
                                })

                                // setSubTitle(val.subTitle ?? "")
                                setDetail(val.detail ?? "")
                                setPromotion(val.promotion ?? "")

                                // subTitleRef.current = val.subTitle ?? ""
                                detailRef.current = val.detail ?? ""
                                promotionRef.current = val.promotion ?? ""

                                setPredictionIdSelected(val.predictionId)
                                setOperation({ type: "edit", isOpen: true })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>
                        {/* {"  "} */}

                        {/* <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handlePredictionDelete(val.predictionId)

                                // reload
                                await getPredictionBySearch(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm> */}
                    </>
            });
        });
        setPredictionList(tempList)

        setPredictionLoading(false)
    }

    const onPredictionFinish = async (values) => {
        let obj = {
            predictionId: values.predictionId ? values.predictionId : null,
            title: values.title ? values.title : null,
            price: values.price ? values.price : null,
            subTitle: values.subTitle ? values.subTitle : null,
            detail: detailRef.current ? detailRef.current : null,
            promotion: promotionRef.current ? promotionRef.current : null,
            imgCoverUrl: imgCoverPredictionURL.imageUrl ? imgCoverPredictionURL.imageUrl : null,
            videoUrl: vdoCourseURL.videoUrl ? vdoCourseURL.videoUrl : null,
            isCoverTypeDisplay: values.isCoverTypeDisplay ? values.isCoverTypeDisplay : null,
        }
        // console.log("onFinish : ", obj)

        if (operation.type === "add") {
            const result = await insertPredictionFetch(obj)
            if (result.isSuccess) {
                // console.log("result?.formData?.PredictionId : ", result?.formData?.predictionId)
                setPredictionIdSelected(result?.formData?.predictionId)
                Notification('success', 'สร้างสำเร็จ')
                window.scrollTo(0, 0)
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (operation.type === "edit") {
            const result = await updatePredictionByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'บันทึกสำเร็จ')
                window.scrollTo(0, 0)
            } else {
                Notification('error', 'ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getPredictionBySearch(null, null, null)

        // set default
        // if (operation.type !== "add") {
        setFormPredictionDefault()
        // }
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getPredictionBySearch(title, startedDate, endDate)
    }

    // const handlePredictionDelete = async (id) => {
    //     const result = await deletePredictionByIdFetch(id)
    //     if (result.isSuccess) {
    //         Notification('success', 'ลบสำเร็จ')
    //     } else {
    //         Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
    //     }
    // }

    const onPredictionPagine = (n) => {
        setPagePredictionCurrent(n.current)
    }

    const setFormPredictionDefault = () => {
        formPrediction.resetFields()

        setImgCoverPredictionURL({
            loading: false,
            imageUrl: null
        })

        setVdoCourseURL({
            loading: false,
            videoUrl: null
        })

        // setSubTitle(null)
        setDetail(null)
        setPromotion(null)

        // subTitleRef.current = null
        detailRef.current = null
        promotionRef.current = null

        setPredictionIdSelected(null)
        setOperation({ type: null, isOpen: false })
    }

    useEffect(() => {
        getPredictionBySearch(null, null, null)
    }, [])

    // console.log("predictionIdSelected : ", predictionIdSelected)

    return (
        <Row>
            <Col span={8}>
                <label>จัดการพยากรณ์</label>
            </Col>
            <Col span={16}>
                {!operation.isOpen ?
                    <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item
                                    label="ชื่อพยากรณ์"
                                    name="title"
                                    style={{ width: '100%' }}
                                >
                                    <Input />
                                </Form.Item>
                            </div>

                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item
                                    label="ห้วงวันที่สร้าง" name="dateRange"
                                    style={{ width: '100%' }}
                                >
                                    <RangePicker />
                                </Form.Item>
                            </div>

                            <div style={{ paddingLeft: 10, marginTop: 3 }}>
                                <Button
                                    style={{ float: 'right', width: 70 }}
                                    type="primary"
                                    onClick={() => {
                                        audioReboundRef.current.play()
                                        formSearch.submit()}}
                                >
                                    ค้นหา
                                </Button>
                            </div>
                        </div>
                    </Form>
                    : []
                }
            </Col>

            {/* <Col span={24} style={{ paddingBottom: 20 }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                    <div style={{ paddingLeft: 12 }}>
                        <Button
                            type={!operation.isOpen ? "primary" : "default"}
                            style={{ float: 'right' }}
                            onClick={() => {
                                formPrediction.resetFields()
                                setPredictionIdSelected(null)

                                setImgCoverPredictionURL({
                                    loading: false,
                                    imageUrl: null
                                })

                                setVdoCourseURL({
                                    loading: false,
                                    videoUrl: null
                                })

                                setOperation({ type: "add", isOpen: !operation.isOpen })
                            }}
                        >
                            {!operation.isOpen ? "เพิ่มพยากรณ์" : "ยกเลิกเพิ่มพยากรณ์ปรากฏกา"}
                        </Button>{"   "}
                    </div>
                </div>
            </Col> */}

            {operation.isOpen ?
                <Col span={24}>
                    <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 8 }}>
                        <Row gutter={[16, 0]}>
                            <Col xs={24} md={24} xl={24}>
                                <Form
                                    layout="vertical"
                                    form={formPrediction}
                                    onFinish={onPredictionFinish}
                                >
                                    <Row gutter={[16, 0]}>
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item name="predictionId" style={{ display: "none" }}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name="title"
                                                label="ชื่อพยากรณ์"
                                                rules={[{ required: true, message: 'กรุณากรอกชื่อพยากรณ์' }]}
                                            >
                                                <Input.TextArea
                                                    style={{ width: "100%" }}
                                                    autoSize={{ minRows: 1, maxRows: 5 }}
                                                    maxLength={200}
                                                    showCount
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                name="price"
                                                label="ราคาพยากรณ์"
                                                rules={[{ required: true, message: 'กรุณากรอกราคาพยากรณ์' }]}
                                            >
                                                <InputNumber style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="subTitle"
                                                label="รายละเอียดย่อย"
                                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดย่อย' }]}
                                            >
                                                <Input.TextArea
                                                    style={{ width: "100%" }}
                                                    autoSize={{ minRows: 2, maxRows: 10 }}
                                                    maxLength={500}
                                                    showCount
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="detail"
                                                label="คำแนะนำ"
                                            >
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'heading', '|',
                                                                'fontfamily', 'fontsize', '|',
                                                                'bold', 'italic', 'underline', '|',
                                                                'alignment', '|',
                                                                'fontColor', 'fontBackgroundColor', '|',
                                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                                'code', 'codeBlock', '|',
                                                                'undo', 'redo'
                                                            ],
                                                            removeButtons: 'Subscript,Superscript',
                                                            height: 350,
                                                        },
                                                    }}
                                                    data={detail}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData()
                                                        detailRef.current = data
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="promotion"
                                                label="โปรโมชั่น"
                                            >
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'heading', '|',
                                                                'fontfamily', 'fontsize', '|',
                                                                'bold', 'italic', 'underline', '|',
                                                                'alignment', '|',
                                                                'fontColor', 'fontBackgroundColor', '|',
                                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                                'code', 'codeBlock', '|',
                                                                'undo', 'redo'
                                                            ],
                                                            removeButtons: 'Subscript,Superscript',
                                                            height: 350,
                                                        },
                                                    }}
                                                    data={promotion}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData()
                                                        promotionRef.current = data
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <div style={{ display: "grid" }}>
                                                <label style={{ paddingBottom: 6 }}></label>
                                                {imgCoverPredictionURL?.imageUrl ?
                                                    <img
                                                        style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                                        src={`${serverUrl}/${imgCoverPredictionURL.imageUrl}`}
                                                    />
                                                    :
                                                    <img
                                                        style={{ width: 207, borderRadius: 8 }}
                                                        src={`./assets/images/default/df-img.png`}
                                                    />
                                                }
                                                <div style={{ paddingTop: 12 }}>
                                                    <Upload
                                                        {...optionCoverPredictionImage}
                                                        accept='image/jpeg, image/png, image/jfif'
                                                        style={{ width: "100%" }}
                                                        maxCount={1}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                    >
                                                        <Button
                                                            type="default"
                                                            style={{ width: "100%" }}
                                                            icon={imgCoverPredictionURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                            onClick={() => audioReboundRef.current.play()}
                                                        >อัพโหลดรูปภาพ</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <div style={{ display: "grid" }}>
                                                <label style={{ paddingBottom: 6 }}>วิดีโอโหร</label>
                                                {vdoCourseURL?.videoUrl ?
                                                    <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                        <Video
                                                            url={vdoCourseURL.videoUrl}
                                                            title={""}
                                                            height={"100%"}
                                                            width={"100%"}
                                                        />
                                                    </div>
                                                    :
                                                    <img
                                                        style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                        src={`./assets/images/default/df-vdo.png`}
                                                    />
                                                }
                                                <div style={{ paddingTop: 12 }}>
                                                    <Upload
                                                        {...optionCourseVideo}
                                                        accept='.mp4'
                                                        style={{ width: "100%" }}
                                                        maxCount={1}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                    >
                                                        <Button
                                                            type="default"
                                                            style={{ width: "100%" }}
                                                            icon={vdoCourseURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                            onClick={audioReboundRef.current.play}
                                                        >อัพโหลดวิดีโอ</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                name="isCoverTypeDisplay"
                                                label="เเสดงปกด้วยรูปภาพหรือวิดีโอ"
                                                rules={[{ required: true, message: 'กรุณาเลือกประเภทการเเสดง' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    optionFilterProp="children"
                                                    allowClear
                                                >
                                                    <Option value={1}>รูปภาพ</Option>
                                                    <Option value={2}>วิดีโอ</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>

                            <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                    <div style={{ paddingLeft: 12 }}>
                                        <Button
                                            type="primary"
                                            disabled={operation.type === "add" && predictionIdSelected ? true : false}
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                formPrediction.submit()
                                            }}
                                        >
                                            {operation.type === "add" ? "สร้างพยากรณ์" : []}
                                            {operation.type === "edit" ? "บันทึก" : []}
                                        </Button>
                                    </div>

                                    <div style={{ paddingLeft: 12 }}>
                                        {operation.type === "add" && predictionIdSelected ?
                                            <Button
                                                type="default"
                                                onClick={() => {
                                                    audioReboundRef.current.play()
                                                    setFormPredictionDefault()
                                                }}
                                            >
                                                ปิดการเเก้ไข
                                            </Button>
                                            : []
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                : []
            }

            {!operation.isOpen ?
                <Col span={24}>
                    <Table
                        loading={predictionLoading}
                        columns={columnsPrediction}
                        dataSource={predictionList}
                        pagination={{
                            current: pagePredictionCurrent,
                            pageSize: 10,
                            total: predictionList?.length
                        }}
                        onChange={(n) => onPredictionPagine(n)}
                    >
                    </Table>
                </Col>
                : []
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
