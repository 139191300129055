import ReactPlayer from 'react-player'
import { serverUrl } from '../../../constants'
import { useEffect, useState } from 'react'

export default function Video(props) {

    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        setPlaying(props?.playing)
    }, [props?.playing])

    // console.log("playing : ", playing)
    
    return (
        <div>
            <ReactPlayer
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onContextMenu={e => e.preventDefault()}
                url={`${serverUrl}/${props.url}`}
                controls
                playing={playing}
                width={props?.width ? props?.width : "100%"}
                height={props?.height ? props?.height : "100%"}
            />

            {props?.title ?
                <div>
                    <label>{props?.title}</label>
                </div>
                : []
            }
        </div>
    )
}