import { httpClient } from "../../../../../utils/HttpClient"

const updateCertificateByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`certificate/update-certificate-by-id`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

export {
    // get

    // insert

    // update
    updateCertificateByIdFetch,

    // delete

}