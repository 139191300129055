import { httpClient } from "../../../../../utils/HttpClient"

const getPaymentOrderBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`payment/get-payment-order-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentStatusAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(`payment/get-payment-status-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentTypeAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(`payment/get-payment-type-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentOrderByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`payment/update-payment-order-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deletePaymentOrderByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`payment/delete-payment-order-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPaymentOrderBySearchFetch,
    getPaymentStatusAllFetch,
    getPaymentTypeAllFetch,

    // insert

    // update
    updatePaymentOrderByIdFetch,

    // delete
    deletePaymentOrderByIdFetch
}