import { httpClient } from "../../../../../utils/HttpClient";

const getSuggestionBySearchFetch = async (value) => {
    // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`suggestion/get-suggestion-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {
        // status 404
        return null
    }
}

const insertSuggestionFetch = async (value) => {
    // Done
    try {
        const result = await httpClient.post(`suggestion/insert-suggestion`, { value })
        return result.data
    } catch (err) {
        // status 404
        return null
    }
}

const updateSuggestionByIdFetch = async (value) => {
    // Done
    try {
        const result = await httpClient.put(`suggestion/update-suggestion-by-id`, { value })
        return result.data
    } catch (err) {
        // status 404
        return null
    }
}

const deleteSuggestionByIdFetch = async (id) => {
    // Done
    try {
        const result = await httpClient.delete(`suggestion/delete-suggestion-by-id` + `/${id}`)
        return result.data
    } catch (err) {
        // status 404
        return null
    }
}

export {
    // get
    getSuggestionBySearchFetch,

    // insert
    insertSuggestionFetch,

    // update
    updateSuggestionByIdFetch,

    // delete
    deleteSuggestionByIdFetch
}