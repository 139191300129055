import { httpClient } from "../../../utils/HttpClient"

const getDetectPredictionRoomExpiredByIdFetch = async (value) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`payment/get-detect-prediction-room-expired-by-id?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const insertPredictionRoomFetch = async (value) => {
    try {
        const result = await httpClient.post(`prediction_room/insert-prediction-room`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updatePredictionRoomActiveByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`payment/update-prediction-room-active-by-id`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

export {
    // get
    getDetectPredictionRoomExpiredByIdFetch,
    
    // insert
    insertPredictionRoomFetch,

    // update
    updatePredictionRoomActiveByIdFetch,

    // delete
    
}
