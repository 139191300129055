import { httpClient } from "../../../../../utils/HttpClient";

const getUserBySearchFetch = async (value) => {
  try {
    const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
    const queryParams = new URLSearchParams(filteredValue).toString()
    const result = await httpClient.get(`user/get-user-by-search?${queryParams}`)
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getMyClassroomByIdFetch = async (uid) => {
  try {
    const result = await httpClient.get(`user/get-my-classroom-by-id?uid=${uid}`)
    if (result.data.isSuccess) {
      return result.data.formData
    } else {
      return null
    }
  } catch (err) {
    // status 404
    return null
  }
}

const getMyPredictionRoomByIdFetch = async (uid) => {
  try {
    const result = await httpClient.get(`user/get-my-prediction-room-by-id?uid=${uid}`)
    if (result.data.isSuccess) {
      return result.data.formData
    } else {
      return null
    }
  } catch (err) {
    // status 404
    return null
  }
}

const updateUserInfoByIdFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.put(`user/update-user-info-by-id`, { value })
    return result.data
  } catch (err) {
    // status 404
    return null
  }
}

const updateClassroomByIdFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.put(`user/update-classroom-by-id`, { value })
    return result.data
  } catch (err) {
    // status 404
    return null
  }
}

const updatePredictionRoomByIdFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.put(`user/update-prediction-room-by-id`, { value })
    return result.data
  } catch (err) {
    // status 404
    return null
  }
}

const deletePredictionRoomByIdFetch = async (id) => {
  // Done
  try {
    const result = await httpClient.delete(`user/delete-user-prediction-room-by-id/${id}`)
    return result.data
  } catch (err) {
    // status 404
    return null
  }
}

const deleteClassroomByIdFetch = async (id) => {
  // Done
  try {
    const result = await httpClient.delete(`delete-classroom-by-id/${id}`)
    return result.data
  } catch (err) {
    // status 404
    return null
  }
}

const deleteUserByIdFetch = async (id) => {
  // Done
  try {
    const result = await httpClient.delete(`user/delete-user-by-id/${id}`)
    return result.data
  } catch (err) {
    // status 404
    return null
  }
}

export {
  // get
  getUserBySearchFetch,
  getMyClassroomByIdFetch,
  getMyPredictionRoomByIdFetch,

  // insert

  // update
  updateUserInfoByIdFetch,
  updateClassroomByIdFetch,
  updatePredictionRoomByIdFetch,

  // delete
  deleteUserByIdFetch,
  deleteClassroomByIdFetch,
  deletePredictionRoomByIdFetch
}
