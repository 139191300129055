import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getDetectClassroomExpiredByIdFetch = async (value) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`payment/get-detect-classroom-expired-by-id?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const insertClassroomFetch = async (value) => {
    try {
        const result = await httpClient.post(`classroom/insert-classroom`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateClassroomActiveByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`payment/update-classroom-active-by-id`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

export {
    // get
    getDetectClassroomExpiredByIdFetch,
    
    // insert
    insertClassroomFetch,

    // update
    updateClassroomActiveByIdFetch,

    // delete
    
}
