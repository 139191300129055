import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getPredictionBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`prediction/get-prediction-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertPredictionFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_PREDICTION_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePredictionByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`prediction/update-prediction-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deletePredictionByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`prediction/delete-prediction-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPredictionBySearchFetch,

    // insert
    insertPredictionFetch,

    // update
    updatePredictionByIdFetch,

    // delete
    deletePredictionByIdFetch,
}