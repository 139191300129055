/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { 
    deleteNewsByIdFetch, 
    getNewsBySearchFetch, 
    insertNewsFetch, 
    updateNewsByIdFetch 
} from './API'

const { TextArea } = Input;
const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'รายการ',
        dataIndex: 'title',
        width: "40%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: 'สถานะ',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>ข่าวสารเปิด</label>
                        :
                        <label>ข่าวสารปิด</label>
                    }
                </>
            )
        }
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function NewsManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const audioReboundRef = useRef()

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const getNewsAll = async (title, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getNewsAll : ", objSearch)
        const result = await getNewsBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                audioReboundRef.current.play()
                                form.setFieldsValue({
                                    newsId: val.newsId,
                                    title: val.title,
                                    isActive: val.isActive,
                                })
                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleNewsDelete(val.newsId)

                                // reload
                                await getNewsAll(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef.current.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            newsId: values.newsId ? values.newsId : null,
            title: values.title ? values.title : null,
            isActive: values.isActive,
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertNewsFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateNewsByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getNewsAll(null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        // let objSearch = {
        //     name, startDate, endDate
        // }
        // console.log("onSearchFinish : ", objSearch)
        await getNewsAll(title, startedDate, endDate)
    }

    const handleNewsDelete = async (id) => {
        const result = await deleteNewsByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.setFieldsValue({
            newsId: undefined,
            title: undefined,
            isActive: undefined,
        })

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getNewsAll(null, null, null)
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการข่าวสาร</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อประกาศ"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef.current.play()
                                    formSearch.submit()}}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        audioReboundRef.current.play()
                        form.resetFields()

                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={600}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="newsId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="ข้อความข่าว"
                                rules={[{ required: true, message: 'กรุณากรอกข้อความข่าว' }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 6, maxRows: 10 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="isActive"
                                label="เเสดงข่าวหรือไม่ ?"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>เปิด</Option>
                                    <Option key={1} value={false}>ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
