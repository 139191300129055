import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getCourseBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`course/get-course-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getVideoCourseBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`course/get-video-course-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertCourseFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`course/insert-course`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertVideoCourseFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`course/insert-video-course`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`course/update-course-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateVideoCourseByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`course/update-video-course-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCourseByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`course/delete-course-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteVideoCourseByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`course/delete-video-course-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCourseBySearchFetch,
    getVideoCourseBySearchFetch,

    // insert
    insertCourseFetch,
    insertVideoCourseFetch,

    // update
    updateCourseByIdFetch,
    updateVideoCourseByIdFetch,

    // delete
    deleteCourseByIdFetch,
    deleteVideoCourseByIdFetch,
}