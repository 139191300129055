import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getDetectUsernameDuplicateFetch = async (username) => {
    try {
        const result = await httpClient.post(server.GET_DETECT_USERNAME_DUPLICATE_URL, { username })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDetectEmailDuplicateFetch = async (email) => {
    try {
        const result = await httpClient.post(server.GET_DETECT_EMAIL_DUPLICATE_URL, { email })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDetectPhoneDuplicateFetch = async (phone) => {
    try {
        const result = await httpClient.post(server.GET_DETECT_PHONE_DUPLICATE_URL, { phone })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getProvincesFetch = async () => {
    try {
        const result = await httpClient.get(`auth/get-provinces-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCountryFetch = async () => {
    try {
        const result = await httpClient.get(`auth/get-country-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPrefixAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PREFIX_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertUserFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_USERS_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getDetectUsernameDuplicateFetch,
    getDetectEmailDuplicateFetch,
    getDetectPhoneDuplicateFetch,
    getProvincesFetch,
    getPrefixAllFetch,
    getCountryFetch,

    // insert
    insertUserFetch,

    // update

    // delete

}