/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Select,
    Popconfirm,
    Upload,
    InputNumber,
    Divider,
    QRCode,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import {
    deleteAnswerListByIdFetch,
    deleteQuestionListByIdFetch,
    getExamBySearchFetch,
    getExamInspectLogByIdFetch,
    getExamRoomStatusAllFetch,
    getExamStatusAllFetch,
    getQuestionListByIdFetch,
    insertAnswerListFetch,
    insertExamInspectLogFetch,
    insertQuestionListFetch,
    updateAnswerScoreByIdFetch,
    updateExamByIdFetch,
    updateExamRoomByIdFetch,
    updateQuestionListByIdFetch
} from '../../../exam/API'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import Video from '../../../../common/components/video'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import parse from 'html-react-parser'
import { updateCertificateByIdFetch } from './API'

const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'สอบ Certificate',
        dataIndex: 'examForCertificate',
        width: "20%",
    },
    {
        title: 'ผู้สอบ',
        dataIndex: 'username',
        width: "20%",
    },
    {
        title: 'ผลสอบ',
        dataIndex: 'examRoomStatus',
        width: "15%",
    },
    {
        title: 'สถานะข้อสอบ',
        dataIndex: 'examStatus',
        width: "15%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        align: "center",
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        fixed: 'right',
        align: "center",
        width: "10%"

    },
]

const questionColumns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'หัวข้อโจทย์',
        dataIndex: 'title',
        width: "20%",
    },
    {
        title: 'คะเเนนที่ทำได้',
        dataIndex: 'score',
        width: "15%",
    },
    {
        title: 'คะเเนนเต็ม',
        dataIndex: 'fullScore',
        width: "15%",
    },
    {
        title: 'เกณฑ์คะเเนนที่ผ่าน',
        dataIndex: 'minimumPassScore',
        width: "15%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        align: "center",
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        fixed: 'right',
        align: "center",
        width: "15%"

    },
]

const formatDate = "DD/MM/YYYY"

export default function ExamInspect() {

    const [list, setList] = useState([])
    const [questionList, setQuestionList] = useState([])

    const examListSelectedRef = useRef([])
    const questionItemSelectedRef = useRef([])
    const [questionListSelected, setQuestionListSelected] = useState([])
    
    const audioReboundRef = useRef()

    const [score, setScore] = useState([])
    const [sumFullScore, setSumFullScore] = useState([])
    const [sumMinimumPassScore, setSumMinimumPassScore] = useState([])

    const [loading, setLoading] = useState(true)

    const [formQuestion] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null,
        data: null
    })

    const [examStatus, setExamStatus] = useState([])
    const [examRoomStatus, setExamRoomStatus] = useState([])

    const [detail, setDetail] = useState()
    const detailRef = useRef()

    const [imageQuestionURL, setImageQuestionURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [videoQuestionURL, setVideoQuestionURL] = useState({
        loading: false,
        videoUrl: null
    })

    const [questionModal, setQuestionModal] = useState({
        isShow: false,
        title: null,
        type: null
    })

    const [examInspectModal, setExamInspectModal] = useState({
        isShow: false,
        title: null,
        type: null
    })

    const [pdfCertificateURL, setPdfCertificateURL] = useState({
        loading: false,
        pdfUrl: null
    })

    const optionQuestionImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "exam/question/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImageQuestionURL({
                        imageUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setImageQuestionURL({
                    loading: true,
                    imageUrl: imageQuestionURL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionQuestionVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "exam/question/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setVideoQuestionURL({
                        videoUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setVideoQuestionURL({
                    loading: true,
                    videoUrl: videoQuestionURL.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionCertificatePdf = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "certificate/pdf"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setPdfCertificateURL({
                        pdfUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setPdfCertificateURL({
                    loading: true,
                    pdfUrl: pdfCertificateURL.pdfUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดไฟล์ Certificate สำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดไฟล์ Certificate ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getExamBySearch = async (username, examStatusId, examRoomStatusId, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            username, examStatusId, examRoomStatusId, startedDate, endDate
        }
        const result = await getExamBySearchFetch(objSearch)
        // console.log("getExaหmBySearchFetch : ", result)

        let tempList = []
        result?.map((val, index) => {
            let examStatusId = val?.exam_status?.examStatusId
            let examRoomStatusId = val?.exam_room?.exam_room_status?.examRoomStatusId

            tempList.push({
                index: (index + 1),
                examForCertificate: val?.exam_room?.course?.title,
                username: val?.exam_room?.user?.username,
                examRoomStatus: val?.exam_room?.exam_room_status?.name,
                examStatus: val?.exam_status?.name,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                operator:
                    <>
                        {examStatusId === 1 && examRoomStatusId === 1 ?
                            <Button
                                type="primary"
                                // style={{  }}s
                                onClick={async () => {
                                    audioReboundRef?.current?.play()
                                    examListSelectedRef.current = val
                                    await getQuestionListById(val.examId)
                                    setModal({ isShow: true, title: "สร้างข้อสอบ", data: val })
                                }}
                            >
                                <label style={{ cursor: "pointer" }}>สร้างข้อสอบ</label>
                            </Button>
                            : []
                        }

                        {examStatusId === 2 && [2, 3, 5, 6].includes(examRoomStatusId) ?
                            <Button
                                type="primary"
                                // style={{  }}
                                onClick={async () => {
                                    audioReboundRef?.current?.play()
                                    examListSelectedRef.current = val
                                    await getQuestionListById(val.examId)
                                    setModal({ isShow: true, title: "ดูข้อสอบ / ผลสอบ", data: val })
                                }}
                            >
                                <label style={{ cursor: "pointer" }}>ดูข้อสอบ / ผลสอบ</label>
                            </Button>
                            : []
                        }

                        {examStatusId === 2 && examRoomStatusId === 4 ?
                            <Button
                                type="primary"
                                // style={{  }}
                                onClick={async () => {
                                    audioReboundRef?.current?.play()
                                    examListSelectedRef.current = val
                                    await getQuestionListById(val.examId)
                                    setExamInspectModal({
                                        isShow: true,
                                        title: "ตรวจข้อสอบ",
                                        type: "inspect"
                                    })
                                }}
                            >
                                <label style={{ cursor: "pointer" }}>ตรวจข้อสอบ</label>
                            </Button>
                            : []
                        }
                    </>
            })

        })
        setList(tempList)

        setLoading(false)
    }

    const getQuestionListById = async (examId) => {
        const result = await getQuestionListByIdFetch(examId)
        // console.log("getQuestionListByIdFetch : ", result)
        setQuestionListSelected(result)

        let tmpScore = new Array()
        let tmpSumFullScore = 0
        let tmpSumMinimumPassScore = 0
        result?.map((item, index) => {
            tmpScore.push({
                answerListId: item?.answer_list?.answerListId,
                score: null, // item?.answer_list?.score,
                fullScore: item.fullScore
            })
            tmpSumFullScore += item?.fullScore
            tmpSumMinimumPassScore += item?.minimumPassScore
        })
        setScore(tmpScore)
        setSumFullScore(tmpSumFullScore)
        setSumMinimumPassScore(tmpSumMinimumPassScore)
        // console.log("tmpScore : ", tmpScore)
        // console.log("tmpSumFullScore : ", tmpSumFullScore)
        // console.log("tmpSumMinimumPassScore : ", tmpSumMinimumPassScore)

        let examStatusId = examListSelectedRef.current?.examStatusId ?? null
        let examRoomStatusId = examListSelectedRef.current?.exam_room?.examRoomStatusId ?? null
        // console.log("examStatusId : ", examListSelectedRef.current.examStatusId)
        // console.log("examRoomStatusId : ", examListSelectedRef.current?.exam_room?.examRoomStatusId)
        // console.log("examListSelected : ", examListSelectedRef.current)

        let tempList = []
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val?.title,
                score: val?.answer_list?.score ?? "-",
                fullScore: val?.fullScore,
                minimumPassScore: val?.minimumPassScore,
                createdAt: val.createdAt ? moment(val?.createdAt).format(formatDate) : "-",
                operator:
                    <>
                        {examStatusId === 1 ?
                            <>
                                <Button
                                    style={{
                                        width: 35,
                                        backgroundColor: "orange",
                                        border: "1px solid orange",
                                        color: 'white',
                                        borderRadius: 50
                                    }}
                                    onClick={async () => {
                                        audioReboundRef?.current?.play()
                                        formQuestion.setFieldsValue({
                                            questionListId: val.questionListId,
                                            title: val.title,
                                            fullScore: val.fullScore,
                                            minimumPassScore: val.minimumPassScore,

                                            examId: val?.examId ?? null,
                                            courseId: val?.exam_room?.course?.courseId ?? null,
                                            uid: val?.exam_room?.user?.uid ?? null
                                        })

                                        setDetail(val.detail ?? "")
                                        detailRef.current = val.detail ?? ""

                                        setImageQuestionURL({
                                            loading: false,
                                            imageUrl: val.imageUrl
                                        })

                                        setVideoQuestionURL({
                                            loading: false,
                                            videoUrl: val.videoUrl
                                        })

                                        setQuestionModal({
                                            isShow: true,
                                            title: "เเก้ไขโจทย์ข้อสอบ",
                                            type: "edit"
                                        })
                                    }}
                                >
                                    <div style={{ marginTop: 4, marginLeft: -8 }}>
                                        <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                                    </div>
                                </Button>{"  "}

                                <Popconfirm
                                    title="คุณยืนยันลบหรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={async () => {
                                        await handleQuestionListDelete(val?.questionListId)
                                        await handleAnswerListDelete(val?.questionListId)

                                        // reload
                                        await getQuestionListById(val?.examId)
                                    }}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        danger
                                        type="primary"
                                        style={{
                                            width: 35,
                                            borderRadius: 50
                                        }}
                                        onClick={() => audioReboundRef?.current?.play()}
                                    >
                                        <div style={{ marginTop: 4, marginLeft: -8 }}>
                                            <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                        </div>
                                    </Button>
                                </Popconfirm>
                            </>
                            : []
                        }

                        {examStatusId === 2 && [2, 3, 5, 6].includes(examRoomStatusId) ?
                            <Button
                                type="primary"
                                // style={{  }}
                                onClick={async () => {
                                    audioReboundRef?.current?.play()
                                    questionItemSelectedRef.current = val
                                    formQuestion.setFieldsValue({
                                        questionListId: val.questionListId,
                                        title: val.title,
                                        fullScore: val.fullScore,
                                        minimumPassScore: val.minimumPassScore,

                                        examId: val?.examId ?? null,
                                        courseId: val?.exam_room?.course?.courseId ?? null,
                                        uid: val?.exam_room?.user?.uid ?? null
                                    })

                                    setDetail(val.detail ?? "")
                                    detailRef.current = val.detail ?? ""

                                    setImageQuestionURL({
                                        loading: false,
                                        imageUrl: val.imageUrl
                                    })

                                    setVideoQuestionURL({
                                        loading: false,
                                        videoUrl: val.videoUrl
                                    })

                                    setQuestionModal({
                                        isShow: true,
                                        title: "คำตอบ",
                                        type: "view"
                                    })
                                }}
                            >
                                <label style={{ cursor: "pointer" }}>ดูคำตอบ</label>
                            </Button>
                            : []
                        }
                    </>
            })
        })

        setQuestionList(tempList)
    }

    const onSearchFinish = async (values) => {
        let username = values?.username ? values.username : null
        let examStatusId = values?.examStatusId ? values.examStatusId : null
        let examRoomStatusId = values?.examRoomStatusId ? values.examRoomStatusId : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getExamBySearch(username, examStatusId, examRoomStatusId, startedDate, endDate)
    }

    const onQuestionFinish = async (values) => {
        let objQuestion = {
            questionListId: values.questionListId ? values.questionListId : null,
            examId: values.examId ? values.examId : null,
            title: values.title ? values.title : null,
            detail: detailRef.current ? detailRef.current : null,
            imageUrl: imageQuestionURL.imageUrl ? imageQuestionURL.imageUrl : null,
            videoUrl: videoQuestionURL.videoUrl ? videoQuestionURL.videoUrl : null,
            fullScore: values.fullScore ? values.fullScore : null,
            minimumPassScore: values.minimumPassScore ? values.minimumPassScore : null,
        }
        // console.log("objQuestion : ", objQuestion)

        if (questionModal.type === "add") {

            const resultQuestion = await insertQuestionListFetch(objQuestion)
            // console.log("resultQuestion : ", resultQuestion)

            let objAnswer = {
                examId: values.examId ? values.examId : null,
                questionListId: resultQuestion.formData?.questionListId ? resultQuestion.formData.questionListId : null,
                courseId: values.courseId ? values.courseId : null,
                uid: values.uid ? values.uid : null,
                answer: null,
                imageUrl: null,
                videoUrl: null,
                score: null,
            }

            const resultAnswer = await insertAnswerListFetch(objAnswer)

            if (resultQuestion.isSuccess && resultAnswer.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
                setQuestionModal({ isShow: false, title: null, type: null })
                window.scrollTo(0, 0)
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (questionModal.type === "edit") {
            const result = await updateQuestionListByIdFetch(objQuestion)
            if (result.isSuccess) {
                Notification('success', 'บันทึกสำเร็จ')
                setQuestionModal({ isShow: false, title: null, type: null })
                window.scrollTo(0, 0)
            } else {
                Notification('error', 'ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getQuestionListById(values.examId ? values.examId : null)

        // set default
        if (questionModal.type !== "add") {
            setFormQuestionDefault()
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        setModal({
            isShow: false,
            title: null,
            data: null
        })
    }

    const getExamStatusAll = async () => {
        const result = await getExamStatusAllFetch()
        setExamStatus(result)
    }

    const getExamRoomStatusAll = async () => {
        const result = await getExamRoomStatusAllFetch()
        setExamRoomStatus(result)
    }

    const handleQuestionListDelete = async (questionListId) => {
        const result = await deleteQuestionListByIdFetch(questionListId)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleAnswerListDelete = async (questionListId) => {
        const result = await deleteAnswerListByIdFetch(questionListId)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getBaseApi = async () => {
        await getExamStatusAll()
        await getExamRoomStatusAll()
        await getExamBySearch(null, null, null, null, null)
    }

    const setFormQuestionDefault = () => {
        formQuestion.resetFields()

        setDetail("")
        detailRef.current = ""

        setImageQuestionURL({
            loading: false,
            imageUrl: null
        })

        setVideoQuestionURL({
            loading: false,
            videoUrl: null
        })
    }

    const detectScore = () => {
        let tmpSumScore = 0
        let isHaveScoreNull = false
        score?.map(item => {
            tmpSumScore += item.score
            if (item.score === null) {
                isHaveScoreNull = true
            }
        })

        // -------------------------------------------
        let message = ""
        let isPass = null
        if (tmpSumScore >= sumMinimumPassScore) {
            message = "ผ่าน"
            isPass = true
        } else {
            message = "ไม่ผ่าน"
            isPass = false
        }

        // -------------------------------------------
        let overLimit = false
        if (tmpSumScore > sumFullScore) {
            overLimit = true
        }
        score?.map(item => {
            if (item.score > item.fullScore) {
                overLimit = true
            }
        })

        // -------------------------------------------
        return {
            tmpSumScore,
            message,
            isHaveScoreNull,
            overLimit,
            isPass
        }
    }

    const downloadQRCode = (fullName) => {
        const canvas = document.getElementById('myqrcode')?.querySelector('canvas')
        if (canvas) {
            const url = canvas.toDataURL()
            const a = document.createElement('a')
            a.download = fullName
            a.href = url;
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("score : ", score)

    return (
        <Row>
            <Col span={24}>
                <label>ตรวจข้อสอบ (ขอใบประกาศนียบัตร)</label>
            </Col>
            <Col span={24} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ผู้สอบ"
                                name="username"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ผลสอบ"
                                name="examRoomStatusId"
                                style={{ width: '100%' }}
                            >
                                <Select
                                    showSearch
                                    style={{ width: 150 }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {examRoomStatus?.map((val, index) =>
                                        <Option key={index} value={val.examRoomStatusId}>{val.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="สถานะข้อสอบ"
                                name="examStatusId"
                                style={{ width: '100%' }}
                            >
                                <Select
                                    showSearch
                                    style={{ width: 150 }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {examStatus?.map((val, index) =>
                                        <Option key={index} value={val.examStatusId}>{val.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    formSearch.submit()}}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {
                    // default
                    setFormDefault()
                }}
                width={900}
                footer={[]}
            >
                <Row gutter={[0, 0]}>
                    <Col span={24} style={{ paddingBottom: 12 }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            {examListSelectedRef.current?.examStatusId === 1 ?
                                <div style={{ paddingLeft: 10 }}>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            audioReboundRef?.current?.play()
                                            formQuestion.setFieldsValue({
                                                questionListId: null,
                                                title: null,
                                                fullScore: null,
                                                minimumPassScore: null,

                                                examId: examListSelectedRef.current?.examId ?? null,
                                                courseId: examListSelectedRef.current?.exam_room?.course?.courseId ?? null,
                                                uid: examListSelectedRef.current?.exam_room?.user?.uid ?? null
                                            })

                                            setDetail("")
                                            detailRef.current = ""

                                            setImageQuestionURL({
                                                loading: false,
                                                imageUrl: null
                                            })

                                            setVideoQuestionURL({
                                                loading: false,
                                                videoUrl: null
                                            })

                                            setQuestionModal({
                                                isShow: true,
                                                title: "สร้างโจทย์ข้อสอบ",
                                                type: "add"
                                            })
                                        }}
                                    >
                                        เพิ่มโจทย์
                                    </Button>
                                </div>
                                : []
                            }

                            {questionList?.length > 0 && examListSelectedRef.current?.examStatusId === 1 ?
                                <div style={{ paddingLeft: 10 }}>
                                    <Button
                                        type="primary"
                                        onClick={async () => {
                                            audioReboundRef?.current?.play()

                                            // console.log("examListSelectedRef.current : ", examListSelectedRef.current)

                                            let objExam = {
                                                examStatusId: 2,
                                                examId: examListSelectedRef.current?.examId
                                            }
                                            const resultExam = await updateExamByIdFetch(objExam)

                                            let objExamRoom = {
                                                examRoomStatusId: 2,
                                                examRoomId: examListSelectedRef.current?.examRoomId
                                            }
                                            const resultExamRoom = await updateExamRoomByIdFetch(objExamRoom)

                                            if (resultExam.isSuccess && resultExamRoom.isSuccess) {
                                                Notification('success', 'ข้อสอบถูกสร้างสำเร็จ')
                                                setFormDefault()
                                                await getExamBySearch(null, null, null, null, null)
                                            } else {
                                                Notification('error', 'ไม่สามารถยืนยันสร้างข้อสอบได้ กรุณาลองใหม่อีกครั้ง')
                                            }
                                        }}
                                    >
                                        ยืนยันสร้างข้อสอบ
                                    </Button>
                                </div>
                                :
                                []
                            }
                        </div>
                    </Col>

                    <Col span={24} style={{ paddingBottom: 12 }}>
                        <Table
                            loading={loading}
                            columns={questionColumns}
                            dataSource={questionList}
                            pagination={{
                                current: pageCurrent,
                                pageSize: 10,
                                total: list.length
                            }}
                            onChange={(n) => onPagine(n)}
                        >
                        </Table>
                    </Col>
                </Row>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{questionModal.title}</label></strong>}
                visible={questionModal.isShow}
                onCancel={() => {
                    // default
                    setFormQuestionDefault()
                    setQuestionModal({ isShow: false, title: null, type: null })
                }}
                width={questionModal.type === "view" ? 500 : 900}
                footer={
                    questionModal.type !== "view" ?
                        [
                            <Button
                                style={{ width: 70 }}
                                type="default"
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    // default
                                    setFormQuestionDefault()
                                    setQuestionModal({ isShow: false, title: null, type: null })
                                }}
                            >
                                ยกเลิก
                            </Button>,
                            <Button
                                style={{ width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    formQuestion.submit()
                                }}
                            >
                                บันทึก
                            </Button>
                        ]
                        :
                        []
                }
            >
                {questionModal.type === "view" ?
                    <Row>
                        <Col span={8}>
                            <label>หัวข้อโจทย์</label>
                        </Col>
                        <Col span={16}>
                            <label>{questionItemSelectedRef.current?.title ?? "-"}</label>
                        </Col>

                        <Col span={8}>
                            <label>รายละเอียดโจทย์</label>
                        </Col>
                        <Col span={16}>
                            <label>{detail ? parse(detail) : "-"}</label>
                        </Col>

                        <Col span={8}>
                            <label>คะเเนนเต็ม</label>
                        </Col>
                        <Col span={16}>
                            <label>{questionItemSelectedRef.current?.fullScore ?? "-"}</label>
                        </Col>

                        <Col span={8}>
                            <label>เกณฑ์คะเเนนที่ผ่าน</label>
                        </Col>
                        <Col span={16}>
                            <label>{questionItemSelectedRef.current?.minimumPassScore ?? "-"}</label>
                        </Col>

                        {imageQuestionURL?.imageUrl ?
                            <>
                                <Col span={8}>
                                    <label>รูปภาพ</label>
                                </Col>
                                <Col span={16}>
                                    <img
                                        style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imageQuestionURL.imageUrl}`}
                                    />
                                </Col>
                            </>
                            : []
                        }

                        {videoQuestionURL?.videoUrl ?
                            <>
                                <Col span={8}>
                                    <label>วิดีโอ</label>
                                </Col>
                                <Col span={16}>
                                    <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                        <Video
                                            url={videoQuestionURL.videoUrl}
                                            title={""}
                                            height={"100%"}
                                            width={"100%"}
                                        />
                                    </div>
                                </Col>
                            </>
                            : []
                        }
                    </Row>
                    :
                    <Form
                        layout="vertical"
                        form={formQuestion}
                        onFinish={onQuestionFinish}
                    >
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <Form.Item name="questionListId" style={{ display: "none" }}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="examId" style={{ display: "none" }}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="courseId" style={{ display: "none" }}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="uid" style={{ display: "none" }}>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="title"
                                    label="หัวข้อโจทย์"
                                    rules={[{ required: true, message: 'กรุณากรอกหัวข้อโจทย์' }]}
                                >
                                    <Input style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={24} xl={24}>
                                <Form.Item name="detail" label="รายละเอียดโจทย์">
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript',
                                                height: 350
                                            }
                                        }}
                                        data={detail}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            detailRef.current = data
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="fullScore"
                                    label="คะเเนนเต็ม"
                                    rules={[{ required: true, message: 'กรุณากรอกคะเเนนเต็ม' }]}
                                >
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="minimumPassScore"
                                    label="เกณฑ์คะเเนนที่ผ่าน"
                                    rules={[{ required: true, message: 'กรุณากรอกเกณฑ์คะเเนนที่ผ่าน' }]}
                                >
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <div style={{ display: "grid" }}>
                                    <label style={{ paddingBottom: 6 }}>ภาพประกอบ</label>
                                    {imageQuestionURL?.imageUrl ?
                                        <img
                                            style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                            src={`${serverUrl}/${imageQuestionURL.imageUrl}`}
                                        />
                                        :
                                        <img
                                            style={{ width: "100%", borderRadius: 8 }}
                                            src={`./assets/images/default/df-img.png`}
                                        />
                                    }
                                    <div style={{ paddingTop: 12 }}>
                                        <Upload
                                            {...optionQuestionImage}
                                            accept='image/jpeg, image/png, image/jfif'
                                            style={{ width: "100%" }}
                                            maxCount={1}
                                            showUploadList={{ showRemoveIcon: false }}
                                        >
                                            <Button
                                                type="default"
                                                style={{ width: "100%" }}
                                                icon={imageQuestionURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                onClick={() => audioReboundRef?.current?.play()}
                                            >อัพโหลดรูปภาพ</Button>
                                        </Upload>
                                    </div>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div style={{ display: "grid" }}>
                                    <label style={{ paddingBottom: 6 }}>วิดีโอประกอบ</label>
                                    {videoQuestionURL?.videoUrl ?
                                        <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                            <Video
                                                url={videoQuestionURL.videoUrl}
                                                title={""}
                                                height={"100%"}
                                                width={"100%"}
                                            />
                                        </div>
                                        :
                                        <img
                                            style={{ width: "100%", borderRadius: 8 }}
                                            src={`./assets/images/default/df-vdo.png`}
                                        />
                                    }
                                    <div style={{ paddingTop: 12 }}>
                                        <Upload
                                            {...optionQuestionVideo}
                                            accept='.mp4'
                                            style={{ width: "100%" }}
                                            maxCount={1}
                                            showUploadList={{ showRemoveIcon: false }}
                                        >
                                            <Button
                                                type="default"
                                                style={{ width: "100%" }}
                                                icon={videoQuestionURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                onClick={() => audioReboundRef?.current?.play()}
                                            >อัพโหลดวิดีโอ</Button>
                                        </Upload>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                }
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{examInspectModal.title}</label></strong>}
                visible={examInspectModal.isShow}
                onCancel={() => {
                    // default
                    setExamInspectModal({ isShow: false, title: null, type: null })
                }}
                width={900}
                footer={
                    examInspectModal.type !== "view" ?
                        [
                            <Button
                                style={{ width: 90 }}
                                type="default"
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    // default
                                    setExamInspectModal({ isShow: false, title: null, type: null })
                                }}
                            >
                                ยกเลิก
                            </Button>,
                            <Button
                                style={{ width: 90 }}
                                disabled={detectScore().isHaveScoreNull || detectScore().overLimit ? true : false}
                                type="primary"
                                onClick={async () => {
                                    audioReboundRef?.current?.play()
                                    // console.log("examListSelectedRef.current : ", examListSelectedRef.current)

                                    let resultExamInspectLog = await getExamInspectLogByIdFetch(examListSelectedRef.current?.examRoomId)
                                    // console.log("getExamInspectLogByIdFetch : ", resultExamInspectLog?.length)

                                    let examRoomStatusId = (detectScore().tmpSumScore > sumMinimumPassScore ? 5 : 6)
                                    if (examRoomStatusId === 6 && resultExamInspectLog?.length >= 2) { // สอบไม่ผ่าน เเละเป็นครั้งที่ 2 เเล้ว
                                        examRoomStatusId = 7 // สิทธิ์สอบครบ 3 ครั้งเเล้ว (โดยครั้งที่ 3 นี้ก็ยังไม่ผ่าน)
                                    }

                                    // update (status) in exam room 
                                    let objExamRoom = {
                                        examRoomStatusId,
                                        examRoomId: examListSelectedRef.current?.examRoomId
                                    }
                                    // console.log("objExamRoom : ", objExamRoom)
                                    const resultExamRoom = await updateExamRoomByIdFetch(objExamRoom)
                                    // console.log("updateExamRoomByIdFetch : ", resultExamRoom)

                                    // insert exam log
                                    let objExamInspectLog = {
                                        examRoomId: examListSelectedRef.current?.examRoomId ?? null,
                                        examId: examListSelectedRef.current?.examId ?? null,
                                        courseId: examListSelectedRef.current?.exam_room?.courseId ?? null,
                                        uid: examListSelectedRef.current?.exam_room?.uid ?? null,
                                        examRoomStatusId,
                                        score: detectScore().tmpSumScore,
                                        fullScore: sumFullScore,
                                        minimumPassScore: sumMinimumPassScore,
                                    }
                                    // console.log("objExamInspectLog : ", objExamInspectLog)
                                    resultExamInspectLog = await insertExamInspectLogFetch(objExamInspectLog)
                                    // console.log("insertExamInspectLogFetch : ", resultExamInspectLog)

                                    // update score
                                    // console.log("score : ", score);
                                    const resultAnswerList = await updateAnswerScoreByIdFetch(score)

                                    let resultCertificate = { isSuccess: true }
                                    if (examRoomStatusId === 5) {
                                        // console.log("certificateId : ", examListSelectedRef.current?.exam_room?.certificate)
                                        let objCertificate = {
                                            dateTimePass: new Date(),
                                            isPlass: true,
                                            pdfUrl: pdfCertificateURL.pdfUrl,
                                            certificateId: examListSelectedRef.current?.exam_room?.certificate?.certificateId,
                                        }
                                        // console.log("objCertificate : ", objCertificate)
                                        resultCertificate = await updateCertificateByIdFetch(objCertificate)
                                    }

                                    if (
                                        resultExamRoom.isSuccess &&
                                        resultExamInspectLog.isSuccess &&
                                        resultAnswerList.isSuccess &&
                                        resultCertificate.isSuccess
                                    ) {
                                        Notification('success', 'ส่งผลสอบสำเร็จ')
                                        setExamInspectModal({ isShow: false, title: null, type: null })
                                        await getExamBySearch(null, null, null, null, null)
                                    } else {
                                        Notification('error', 'ไม่สามารถส่งผลสอบได้ กรุณาลองใหม่อีกครั้ง')
                                    }
                                }}
                            >
                                ส่งผลสอบ
                            </Button>
                        ]
                        :
                        []
                }
            >
                <Row gutter={[0, 12]}>
                    {questionListSelected?.map((item, index) => {
                        return (
                            <Col span={24}>
                                <div style={{ border: "1px solid gray", paddingLeft: 12, paddingRight: 12, paddingTop: 2, paddingBottom: 2, borderRadius: 8 }}>
                                    <div style={{ paddingBottom: 12 }}>
                                        <Row>
                                            <Col span={18}>
                                                <label style={{ fontWeight: 600 }}>{item.title}</label>
                                                <br />
                                                <label>{item.detail ? parse(item.detail) : "-"}</label>
                                            </Col>
                                            <Col span={6}>
                                                <label style={{ fontWeight: 600, float: "right" }}>คะเเนนเต็ม {item.fullScore}</label>
                                                <br />
                                                <label style={{ fontWeight: 600, float: "right" }}>คะเเนนที่ผ่าน {item.minimumPassScore}</label>
                                            </Col>

                                            <Col span={12}>
                                                {item?.imageUrl ?
                                                    <div style={{ paddingRight: 6 }}>
                                                        <img
                                                            style={{ maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                                            src={`${serverUrl}/${item.imageUrl}`}
                                                        />
                                                    </div>
                                                    : []
                                                }
                                            </Col>

                                            <Col span={12}>
                                                {item?.videoUrl ?
                                                    <div style={{ paddingLeft: 6 }}>
                                                        <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                            <Video
                                                                url={item.videoUrl}
                                                                title={""}
                                                                width={"100%"}
                                                            />
                                                        </div>
                                                    </div>
                                                    : []
                                                }
                                            </Col>
                                        </Row>
                                    </div>

                                    <div style={{ paddingBottom: 12 }}>
                                        <div style={{ backgroundColor: "#EEEEEE", borderRadius: 8, paddingLeft: 12, paddingRight: 12, paddingTop: 2, paddingBottom: 2 }}>
                                            <Row>
                                                <Col span={24}>
                                                    <label>{item?.answer_list?.answer ? parse(item.answer_list.answer) : "-"}</label>
                                                </Col>

                                                {item?.answer_list?.imageUrl ?
                                                    <Col span={12}>
                                                        <div style={{ paddingRight: 6 }}>
                                                            <img
                                                                style={{ maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                                                src={`${serverUrl}/${item?.answer_list.imageUrl}`}
                                                            />
                                                        </div>
                                                    </Col>
                                                    : []
                                                }

                                                {item?.answer_list?.videoUrl ?
                                                    <Col span={12}>
                                                        <div style={{ paddingLeft: 6 }}>
                                                            <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                                <Video
                                                                    url={item?.answer_list.videoUrl}
                                                                    title={""}
                                                                    width={"100%"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    : []
                                                }

                                                <Col span={24}>
                                                    <div style={{ paddingBottom: 12 }}>
                                                        <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                                                        <Row>
                                                            <Col span={12}>
                                                                <label>คะเเนนที่ได้</label>
                                                                <br />
                                                                <InputNumber
                                                                    style={{ width: 200 }}
                                                                    onChange={(e) => {
                                                                        let tmp = score.map(val => val.answerListId === item?.answer_list.answerListId ? {
                                                                            answerListId: val.answerListId,
                                                                            score: e,
                                                                            fullScore: val.fullScore
                                                                        } : val)
                                                                        setScore(tmp)
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                    <Col xs={24} md={10} xl={10}>
                        <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <label style={{ fontSize: 22 }} >คะเเนนที่ได้รวม : {detectScore().tmpSumScore} / {sumFullScore}</label>
                            <label style={{ fontSize: 22 }} >เกณฑ์คะเเนนรวมที่ผ่าน : {sumMinimumPassScore}</label>
                            <label style={{ fontSize: 22 }} >ผลสอบ : {detectScore().isHaveScoreNull ? "ยังตรวจไม่ครบทุกโจทย์" : (detectScore().overLimit ? "คะเเนนที่ได้เกิน limit" : detectScore().message)}</label>
                        </div>
                    </Col>

                    {detectScore().isPass ?
                        <Col xs={24} md={14} xl={14}>
                            <Row gutter={[0, 12]}>
                                <Col xs={24} md={8} xl={6}>
                                    <label>Certificate Id : </label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{examListSelectedRef.current?.exam_room?.certificate?.cid}</label>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    <label>ชื่อจริง - นามสกุล</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{examListSelectedRef.current?.exam_room?.user?.firstname}  {examListSelectedRef.current?.exam_room?.user?.lastname}</label>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    <label>QRCode Image</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <div id="myqrcode">
                                        <QRCode
                                            value={examListSelectedRef.current?.exam_room?.certificate?.cid}
                                            bgColor="#fff"
                                            style={{
                                                marginBottom: 16,
                                            }}
                                        />
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                audioReboundRef?.current?.play()
                                                downloadQRCode(`${examListSelectedRef.current?.exam_room?.user?.firstname}  ${examListSelectedRef.current?.exam_room?.user?.lastname}`)
                                            }}
                                        >
                                            ดาวโหลด QRCode
                                        </Button>
                                    </div>
                                </Col>

                                <Col xs={24} md={24} xl={24}>
                                    <div style={{ backgroundColor: "#EEEEEE" }}>
                                        <div style={{ display: "grid", alignItems: "center", justifyContent: "center" }}>
                                            <div style={{ paddingTop: 12 }}>
                                                {pdfCertificateURL?.pdfUrl ?
                                                    <iframe
                                                        src={`${serverUrl}/${pdfCertificateURL.pdfUrl}`}
                                                        style={{ width: "100%", height: 220 }}
                                                    />
                                                    :
                                                    <div style={{ padding: 40, backgroundColor: "white", width: 320, borderRadius: 8 }}>ไฟล์ PDF</div>
                                                }
                                            </div>

                                            <div style={{ paddingTop: 12, paddingBottom: 12 }}>
                                                <Upload
                                                    {...optionCertificatePdf}
                                                    accept='application/pdf'
                                                    style={{ width: "100%" }}
                                                    maxCount={1}
                                                    showUploadList={{ showRemoveIcon: false }}
                                                >
                                                    <Button
                                                        type="default"
                                                        style={{ width: "100%" }}
                                                        icon={pdfCertificateURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                        onClick={() => audioReboundRef?.current?.play()}
                                                    >อัพโหลด PDF ตัวอย่าง</Button>
                                                </Upload>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        : []
                    }
                </Row>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
        
    )
}
