/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { 
    getPlanetaryAspectBySearchFetch,     
    insertPlanetaryAspectFetch, 
    updatePlanetaryAspectByIdFetch,
    deletePlanetaryAspectByIdFetch
} from './API'

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'hierarchy',
        dataIndex: 'hierarchy',
        width: "10%",
    },
    {
        title: 'lexicon',
        dataIndex: 'lexicon',
        width: "10%",
    },
    {
        title: 'key_word',
        dataIndex: 'key_word',
        width: "10%",
    },
    {
        title: 'e_factors',
        dataIndex: 'e_factors',
        width: "10%",
    },
    {
        title: 'factors',
        dataIndex: 'factors',
        width: "5%",
    },
    {
        title: 'equation',
        dataIndex: 'equation',
        width: "10%",
    },
    {
        title: 'meaning',
        dataIndex: 'meaning',
        width: "30%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function PlanetaryAspect() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const audioReboundRef = useRef()


    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const getPlanetaryAspectBySearch = async (objSearch) => {
        setLoading(true)

        // console.log("getPlanetaryAspectBySearchFetch : ", objSearch)
        const result = await getPlanetaryAspectBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                hierarchy: val.hierarchy,
                lexicon: val.lexicon,
                key_word: val.key_word,
                e_factors: val.e_factors,
                factors: val.factors,
                equation: val.equation,
                meaning: val.meaning,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <div style={{ paddingBottom: 6 }}>
                            <Button
                                style={{
                                    width: 35,
                                    backgroundColor: "orange",
                                    border: "1px solid orange",
                                    color: 'white',
                                    borderRadius: 50
                                }}
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    form.setFieldsValue({
                                        planetaryAspectId: val.planetaryAspectId,
                                        hierarchy: val.hierarchy,
                                        lexicon: val.lexicon,
                                        key_word: val.key_word,
                                        e_factors: val.e_factors,
                                        factors: val.factors,
                                        equation: val.equation,
                                        meaning: val.meaning,
                                    })
                                    setModal({ isShow: true, title: "edit" })
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </div>{"  "}

                        <div style={{ paddingBottom: 0 }}>
                            <Popconfirm
                                title="คุณยืนยันลบหรือไม่ ?"
                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                onConfirm={async () => {
                                    await handlePlanetaryAspectDelete(val.knowledgeSourceId)

                                    // reload
                                    await getPlanetaryAspectBySearch({
                                        hierarchy: null,
                                        lexicon: null,
                                        key_word: null,
                                        e_factors: null,
                                        factors: null,
                                        equation: null,
                                        meaning: null,
                                    })
                                }}
                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                            >
                                <Button
                                    danger
                                    type="primary"
                                    style={{
                                        width: 35,
                                        borderRadius: 50
                                    }}
                                    onClick={() => audioReboundRef?.current?.play()}
                                >
                                    <div style={{ marginTop: 0, marginLeft: -8 }}>
                                        <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                    </div>
                                </Button>
                            </Popconfirm>
                        </div>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            planetaryAspectId: values?.planetaryAspectId ? values.planetaryAspectId : null,
            hierarchy: values?.hierarchy ? Number(values.hierarchy) : null,
            lexicon: values?.lexicon ? values.lexicon : null,
            key_word: values?.key_word ? values.key_word : null,
            e_factors: values?.e_factors ? values.e_factors : null,
            factors: values?.factors ? values.factors : null,
            equation: values?.equation ? values.equation : null,
            meaning: values?.meaning ? values.meaning : null,
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertPlanetaryAspectFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updatePlanetaryAspectByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getPlanetaryAspectBySearch({
            hierarchy: null,
            lexicon: null,
            key_word: null,
            e_factors: null,
            factors: null,
            equation: null,
            meaning: null,
        })

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let objSearch = {
            hierarchy: values?.hierarchy ? values.hierarchy : null,
            lexicon: values?.lexicon ? values.lexicon : null,
            key_word: values?.key_word ? values.key_word : null,
            e_factors: values?.e_factors ? values.e_factors : null,
            factors: values?.factors ? values.factors : null,
            equation: values?.equation ? values.equation : null,
            meaning: values?.meaning ? values.meaning : null,
        }
        // console.log("onSearchFinish : ", objSearch)
        await getPlanetaryAspectBySearch(objSearch)
    }

    const handlePlanetaryAspectDelete = async (id) => {
        const result = await deletePlanetaryAspectByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getPlanetaryAspectBySearch({
            hierarchy: null,
            lexicon: null,
            key_word: null,
            e_factors: null,
            factors: null,
            equation: null,
            meaning: null,
        })
    }, [])

    return (
        <Row>
            <Col span={24}>
                <Row style={{ borderBottom: "1px solid gray" }}>
                    <Col span={12}>
                        <label>ข้อมูลคอมพิวเตอร์โหร</label>
                    </Col>

                    <Col span={12} style={{ paddingBottom: 20 }}>
                        <Button
                            type="primary"
                            style={{ float: 'right' }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                form.resetFields()
                                setModal({
                                    isShow: true,
                                    title: "add"
                                })
                            }}
                        >เพิ่มรายการ</Button>
                    </Col>
                </Row>
            </Col>

            <Col span={24} style={{ paddingBottom: 12, paddingTop: 12 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="hierarchy"
                                name="hierarchy"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="lexicon"
                                name="lexicon"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="key_word"
                                name="key_word"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="e_factors"
                                name="e_factors"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="factors"
                                name="factors"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="equation"
                                name="equation"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                label="meaning"
                                name="meaning"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <div style={{ marginTop: 31 }}>
                                <Button
                                    style={{ float: 'right', width: "100%" }}
                                    type="primary"
                                    onClick={() => {
                                        audioReboundRef?.current?.play()
                                        formSearch.submit()
                                    }}
                                >
                                    ค้นหา
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={500}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="planetaryAspectId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="hierarchy"
                                label="hierarchy"
                            // rules={[{ required: true, message: 'กรุณากรอก hierarchy' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="lexicon"
                                label="lexicon"
                            // rules={[{ required: true, message: 'กรุณากรอก lexicon' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="key_word"
                                label="key_word"
                            // rules={[{ required: true, message: 'กรุณากรอก key_word' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="e_factors"
                                label="e_factors"
                            // rules={[{ required: true, message: 'กรุณากรอก e_factors' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="factors"
                                label="factors"
                            // rules={[{ required: true, message: 'กรุณากรอก factors' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="equation"
                                label="equation"
                            // rules={[{ required: true, message: 'กรุณากรอก equation' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="meaning"
                                label="meaning"
                            // rules={[{ required: true, message: 'กรุณากรอก meaning' }]}
                            >
                                <Input.TextArea
                                    style={{ width: "100%" }}
                                    autoSize={{ minRows: 6, maxRows: 10 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </Row>
    )
}
