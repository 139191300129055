/* eslint-disable no-duplicate-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect } from 'react'
import {
    Button,
    Layout,
    Menu
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate, useLocation } from 'react-router-dom'
import UserManage from './manage/user'
import { signInAdminByIdFetch } from './API'
import NewsManage from './manage/news'
import AdminManage from './manage/admin'
import CourseManage from './manage/course'
import HomePage from './page/home'
import PlanetaryAspect from './warehouse/planetaryAspect'
import KhunHor from './warehouse/khunHor'
import Mandala from './warehouse/mandala'
import PredictionManage from './manage/prediction'
import EBookManage from './manage/ebook'
import FeedbackInspect from './inspect/feedback'
import BookInfo from './warehouse/bookInfo'
import LoadingPage from '../../common/components/loading/page'
import ServiceUsManage from './manage/serviceUs'
import UserManualManage from './manage/userManual'
import ContactPage from './page/contact'
import BlogManage from './manage/blog'
import WhyChooseUsManage from './manage/whyChooseUs'
import SuggestionInspect from './inspect/suggestion'
import ExamInspect from './inspect/exam'
import ReviewInspect from './manage/review'
import PreviewProductManage from './manage/previewProduct'
import PaymentInspect from './inspect/payment'

const { Sider } = Layout
const { SubMenu } = Menu

export default function ERP(props) {
    const navigate = useNavigate()
    const location = useLocation()

    const [loading, setLoading] = useState(true)

    let username = location.state ? location.state.username : null
    let password = location.state ? location.state.password : null

    const [selectedMenuItem, setSelectedMenuItem] = useState("manage-user")

    const componentsSwitch = (type) => {
        switch (type) {
            // ---------------------------------------
            case "inspect-suggestion":
                return <SuggestionInspect />
            case "inspect-feedback":
                return <FeedbackInspect />
            case "inspect-exam":
                return <ExamInspect />
            case "inspect-review":
                return <ReviewInspect />
            case "inspect-payment":
                return <PaymentInspect adminLogin={location.state} />

            // ---------------------------------------
            case "manage-course":
                return <CourseManage />
            case "manage-prediction":
                return <PredictionManage />
            case "manage-ebook":
                return <EBookManage />
            case "manage-user":
                return <UserManage />
            case "manage-admin":
                return <AdminManage />
            case "manage-news":
                return <NewsManage />
            case "manage-user-manual":
                return <UserManualManage />
            case "manage-blog":
                return <BlogManage />
            case "manage-service-us":
                return <ServiceUsManage />
            case "manage-why-choose-us":
                return <WhyChooseUsManage />
            case "manage-preview-product":
                return <PreviewProductManage />

            // ---------------------------------------
            case "warehouse-planetary-aspect":
                return <PlanetaryAspect />
            case "warehouse-mandala":
                return <Mandala />
            case "warehouse-khun-hor":
                return <KhunHor />
            case "warehouse-book-info":
                return <BookInfo />

            // ---------------------------------------
            case "page-home":
                return <HomePage />
            case "page-contact":
                return <ContactPage />

            default:
                return null
        }
    }

    const detectLogin = async () => {
        return await signInAdminByIdFetch(username, password) // & where isActive = 1
    }

    const getBaseApi = async () => {
        setLoading(true)
        let result = await detectLogin()
        if (!result?.isLogin) {
            navigate("/login")
        }
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <div style={{ backgroundColor: '#ECECEC' }}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <PageHeader
                            title={
                                <>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, color: "white" }}>Administrator</label><br />
                                    <label style={{ paddingBottom: 0, marginBottom: 0, color: "white", fontWeight: "initial" }}>ผู้ใช้งาน : {location.state ? location.state.username : "-"}</label>
                                </>
                            }
                            extra={[
                                <Button
                                    type='default'
                                    onClick={() => {
                                        navigate(
                                            "/",
                                            { state: null }
                                        )

                                        navigate("/login")
                                    }}
                                    style={{ position: 'absolute', marginTop: 5, marginLeft: -105 }}
                                >ออกจากระบบ</Button>
                            ]}
                            style={{ backgroundColor: 'black' }}
                        >
                        </PageHeader>
                        <Layout>
                            <Sider
                                width={300}
                                className="site-layout-background"
                            >
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    defaultOpenKeys={['mamage-user']}
                                    mode="inline"
                                    theme="dark"
                                    selectedKeys={selectedMenuItem}
                                    onClick={async (e) => {
                                        setSelectedMenuItem(e.key)
                                    }}
                                    style={{ height: '100%' }}
                                >
                                    <SubMenu key="group-menu-1" title="การตรวจสอบ">
                                        <Menu.Item key="inspect-suggestion">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบเเนะนำ หรือเสนอเเนะ</label>
                                        </Menu.Item>

                                        <Menu.Item key="inspect-exam">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจข้อสอบ (ขอใบประกาศนียบัตร)</label>
                                        </Menu.Item>

                                        <Menu.Item key="inspect-feedback">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบ Feedback</label>
                                        </Menu.Item>

                                        <Menu.Item key="inspect-review">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบรรีวิว</label>
                                        </Menu.Item>

                                        <Menu.Item key="inspect-payment">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบการชำระเงิน</label>
                                        </Menu.Item>
                                    </SubMenu>

                                    <SubMenu key="group-menu-2" title="การจัดการ">

                                        <Menu.Item key="manage-user">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการสมาชิก</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-user-manual">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการสอนการใช้งาน</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-blog">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการบทความ</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-course">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการคอร์ส</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-prediction">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการพยากรณ์</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-ebook">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ eBook</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-admin">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ Admin</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-news">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการข่าวสาร</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-service-us">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการบริการ</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-why-choose-us">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการทำไมต้องเลือกเรา</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-preview-product">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ Preview product</label>
                                        </Menu.Item>
                                        
                                        {/* <Menu.Item key="manage-payment-account">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการช่องทางชำระเงิน</label>
                                        </Menu.Item> */}
                                    </SubMenu>

                                    <SubMenu key="group-menu-3" title="เพจเว็บไซต์">
                                        <Menu.Item key="page-home">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Page หน้าหลัก</label>
                                        </Menu.Item>

                                        <Menu.Item key="page-contact">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Page ติดต่อเรา</label>
                                        </Menu.Item>
                                    </SubMenu>

                                    <SubMenu key="group-menu-4" title="คลังข้อมูล">
                                        <Menu.Item key="warehouse-planetary-aspect">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ข้อมูลคอมพิวเตอร์โหร</label>
                                        </Menu.Item>

                                        <Menu.Item key="warehouse-mandala">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ข้อมูลดวงกาลจักร</label>
                                        </Menu.Item>

                                        <Menu.Item key="warehouse-khun-hor">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ข้อมูลขุนโหร</label>
                                        </Menu.Item>

                                        <Menu.Item key="warehouse-book-info">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ข้อมูลหนังสือ</label>
                                        </Menu.Item>
                                    </SubMenu>
                                </Menu>
                            </Sider>
                            <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                                <div style={{ padding: 20 }}>
                                    {componentsSwitch(selectedMenuItem)}
                                </div>
                            </article>
                        </Layout>
                    </div>
                </div>
                :
                <LoadingPage loading={loading} />
            }
        </>
    )
}
