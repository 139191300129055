import { httpClient } from "../../../../../utils/HttpClient"

const getAdminBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`erp/get-admin-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const inspectNewUsernameDuplicateFetch = async (username) => { // Done
    try {
        const result = await httpClient.post(`erp/inspect-new-username-duplicate`, { username })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const inspectOldPasswordMatchesFetch = async (password, adminId) => { // Done
    try {
        const result = await httpClient.post(`erp/inspect-old-password-matches`, { password, adminId })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertAdminFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`erp/insert-admin`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAdminByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`erp/update-admin-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAdminPasswordByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`erp/update-admin-password-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteAdminByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`erp/delete-admin-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getAdminBySearchFetch,
    inspectNewUsernameDuplicateFetch,
    inspectOldPasswordMatchesFetch,

    // insert
    insertAdminFetch,

    // update
    updateAdminByIdFetch,
    updateAdminPasswordByIdFetch,

    // delete
    deleteAdminByIdFetch
}