import { httpClient } from "../../../../../utils/HttpClient"

const getPreviewProductBySearchFetch = async (value) => { // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`preview_product/get-preview-product-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertPreviewProductFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`preview_product/insert-preview-product`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePreviewProductByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`preview_product/update-preview-product-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deletePreviewProductByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`preview_product/delete-preview-product-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPreviewProductBySearchFetch,

    // insert
    insertPreviewProductFetch,

    // update
    updatePreviewProductByIdFetch,

    // delete
    deletePreviewProductByIdFetch
}