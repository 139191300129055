/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Input,
} from 'antd'
import { getPageContactFetch, updatePageContactByIdFetch } from '../../../contract/API'
import { Notification } from '../../../../common/components/notification'
import LoadingPage from '../../../../common/components/loading/page'

export default function ContactPage() {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState(null)

    const getContact = async () => {
        const result = await getPageContactFetch()

        setData(result)
    }

    const onFinish = async (values) => {
        let obj = {
            pageContactId: values.pageContactId ? values.pageContactId : null,
            slogan: values.slogan ? values.slogan : null,
            address: values.address ? values.address : null,
            phone: values.phone ? values.phone : null,
            facebook: values.facebook ? values.facebook : null,
            line: values.line ? values.line : null,
            youtube: values.youtube ? values.youtube : null
        }
        // console.log("obj : ", obj)
        const result = await updatePageContactByIdFetch(obj)

        if (result.isSuccess) {
            Notification('success', 'เเก้ไขสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getContact()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <Row>
                    <Col span={24} style={{ paddingBottom: 20 }}>
                        <label>เพจ (ติดต่อเรา)</label>
                    </Col>
                    <Col xs={24} md={24} xl={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                pageContactId: data?.pageContactId,
                                slogan: data?.slogan,
                                address: data?.address,
                                phone: data?.phone,
                                facebook: data?.facebook,
                                line: data?.line,
                                youtube: data?.youtube
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item name="pageContactId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Slogan"
                                        name="slogan"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Address"
                                        name="address"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Phone"
                                        name="phone"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Facebook"
                                        name="facebook"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Line"
                                        name="line"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Youtube"
                                        name="youtube"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            style={{ float: 'right', width: 70 }}
                                            type="primary"
                                            onClick={() => {
                                                form.submit()
                                            }}
                                        >
                                            บันทึก
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                :
                <LoadingPage loading={loading} />
            }
        </>
    )
}
