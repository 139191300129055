/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
    Upload
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { apiServerUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import Video from '../../../../common/components/video'
import { 
    getPreviewProductBySearchFetch,     
    deletePreviewProductByIdFetch, 
    insertPreviewProductFetch, 
    updatePreviewProductByIdFetch 
} from './API'

const { TextArea } = Input;
const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'รายการ',
        dataIndex: 'title',
        width: "55%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function PreviewProductManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)
    
    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [vdoPreviewProductURL, setVdoPreviewProductURL] = useState({
        loading: false,
        videoUrl: null
    })

    const optionPreviewProductVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "preview_product/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setVdoPreviewProductURL({
                        videoUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setVdoPreviewProductURL({
                    loading: true,
                    videoUrl: vdoPreviewProductURL.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getPreviewProductIdAll = async (title, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("objSearch : ", objSearch)
        const result = await getPreviewProductBySearchFetch(objSearch)
        // console.log("getPreviewProductBySearchFetch : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                form.setFieldsValue({
                                    previewProductId: val.previewProductId,
                                    title: val.title,
                                    productKey: val.productKey,
                                    isActive: val.isActive
                                })

                                setVdoPreviewProductURL({
                                    loading: false,
                                    videoUrl: val.videoUrl
                                })

                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handlePreviewProductDelete(val.previewProductId)

                                // reload
                                await getPreviewProductIdAll(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            previewProductId: values.previewProductId ? values.previewProductId : null,
            title: values.title ? values.title : null,
            productKey: values.productKey ? values.productKey : null,
            videoUrl: vdoPreviewProductURL.videoUrl ? vdoPreviewProductURL.videoUrl : null,
            isActive: values.isActive ? values.isActive : null,
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertPreviewProductFetch(obj)
            if (result?.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updatePreviewProductByIdFetch(obj)
            if (result?.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getPreviewProductIdAll(null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        // let objSearch = {
        //     name, startDate, endDate
        // }
        // console.log("onSearchFinish : ", objSearch)
        await getPreviewProductIdAll(title, startedDate, endDate)
    }

    const handlePreviewProductDelete = async (id) => {
        const result = await deletePreviewProductByIdFetch(id)
        // console.log("result : ", result, id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getPreviewProductIdAll(null, null, null)
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการ Preview product</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อ Product"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    formSearch.submit()}}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        form.resetFields()

                        setVdoPreviewProductURL({
                            loading: false,
                            videoUrl: null
                        })

                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {
                    // default
                    setFormDefault()
                }}
                width={1000}
                onOk={() => {
                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} xl={12}>
                            <Form.Item name="previewProductId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="ชื่อ Product"
                                rules={[{ required: true, message: 'กรุณากรอกหัวข้อ' }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="productKey"
                                label="Product key"
                            >
                                <TextArea
                                    autoSize={{ minRows: 1, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>วิดีโอ Preview product</label>
                                {vdoPreviewProductURL?.videoUrl ?
                                    <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                        <Video
                                            url={vdoPreviewProductURL.videoUrl}
                                            // title={""}
                                            height={"100%"}
                                            width={"100%"}
                                        />
                                    </div>
                                    :
                                    <img
                                        style={{ width: 300, borderRadius: 8 }}
                                        src={`./assets/images/default/df-vdo.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionPreviewProductVideo}
                                        accept='.mp4'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={vdoPreviewProductURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดวิดีโอ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="isActive"
                                label="เเสดงรายการนี้หรือไม่"
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option value={true}>ใช่</Option>
                                    <Option value={false}>ไม่ใช่</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
