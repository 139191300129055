import { httpClient } from "../../../utils/HttpClient"

const getPageHomeFetch = async () => {
    try {
        const result = await httpClient.get(`home/get-page-home`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePageHomeByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(`home/update-page-home-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPageHomeFetch,

    // insert

    // update
    updatePageHomeByIdFetch,

    // delete

}