/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Upload,
    Select
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import {
    deleteWhyChooseUsByIdFetch,
    getWhyChooseUsBySearchFetch,
    insertWhyChooseUsFetch,
    updateWhyChooseUsByIdFetch,
} from './API'

const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'หัวข้อบริการ',
        dataIndex: 'title',
        width: "45%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "15%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function WhyChooseUsManage() {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    // field external
    const [subTitle, setSubTitle] = useState()
    const [detail, setDetail] = useState()

    const subTitleRef = useRef()
    const detailRef = useRef()

    const [imgCoverUrl, setImgCoverUrl] = useState({
        loading: false,
        imageUrl: null
    })

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const optionWhyChooseUsImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "why_choose_us/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgCoverUrl({
                        imageUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setImgCoverUrl({
                    loading: true,
                    imageUrl: imgCoverUrl.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getWhyChooseUsBySearch = async (title, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getWhyChooseUsBySearchFetch : ", objSearch)
        const result = await getWhyChooseUsBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                form.setFieldsValue({
                                    whyChooseUsId: val.whyChooseUsId,
                                    title: val.title,
                                    isActive: val.isActive
                                })

                                setImgCoverUrl({
                                    loading: false,
                                    imageUrl: val.imageUrl
                                })

                                setSubTitle(val.subTitle ?? "")
                                subTitleRef.current = val.subTitle ?? ""

                                setDetail(val.detail ?? "")
                                detailRef.current = val.detail ?? ""

                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleWhyChooseUsDelete(val.whyChooseUsId)

                                // reload
                                await getWhyChooseUsBySearch(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            whyChooseUsId: values.whyChooseUsId ? values.whyChooseUsId : null,
            title: values.title ? values.title : null,
            subTitle: subTitleRef.current ? subTitleRef.current : null,
            detail: detailRef.current ? detailRef.current : null,
            imageUrl: imgCoverUrl.imageUrl ? imgCoverUrl.imageUrl : null,
            isActive: values.isActive ? values.isActive : null,
        }
        // console.log("onFinish : ", obj)

        if (modal.title === "add") {
            const result = await insertWhyChooseUsFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateWhyChooseUsByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getWhyChooseUsBySearch(null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getWhyChooseUsBySearch(title, startedDate, endDate)
    }

    const handleWhyChooseUsDelete = async (id) => {
        const result = await deleteWhyChooseUsByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setDetail("")

        detailRef.current = ""

        setModal({
            isShow: false,
            title: null
        })
    }

    useEffect(() => {
        getWhyChooseUsBySearch(null, null, null)
    }, [])

    return (
        <Row>
            <Col span={8}>
                <label>จัดการบริการต่างๆ</label>
            </Col>
            <Col span={16} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="โหราศาสตร์ไฮไลท์ (จุดเด่น)"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    formSearch.submit()
                                }}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        form.resetFields()

                        setImgCoverUrl({
                            loading: false,
                            imageUrl: null
                        })

                        setSubTitle("")
                        subTitleRef.current = ""

                        setDetail("")
                        detailRef.current = ""

                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={700}
                onOk={() => {
                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 12]}>
                        <Col span={24}>
                            <Form.Item name="whyChooseUsId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="หัวข้อบริการ"
                                rules={[{ required: true, message: 'กรุณากรอกหัวข้อบริการ' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="subTitle"
                                label="รายละเอียดย่อย"
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            // height: 350,
                                        },
                                    }}
                                    data={subTitle}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        subTitleRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="detail"
                                label="รายละทำไมต้องเลือกเรา"
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            // height: 350,
                                        },
                                    }}
                                    data={detail}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        detailRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>ปกรูปภาพ</label>
                                {imgCoverUrl?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxHeight: 207, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imgCoverUrl.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: 207, borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionWhyChooseUsImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imgCoverUrl.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="isActive"
                                label="เเสดงรายการนี้หรือไม่"
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option value={true}>ใช่</Option>
                                    <Option value={false}>ไม่ใช่</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
