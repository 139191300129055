export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING";
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS";
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED";

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE";

export const serverUrl = "https://production-witte-bangkok-phase2-server.onrender.com";
// export const serverUrl = "http://localhost:3001";

export const apiServerUrl = serverUrl + "/api/v1";

export const server = {
  // // ------------------------ AUTH ---------------------------------------------------------------
  // GET_DETECT_USERNAME_DUPLICATE_URL: `auth/get-detect-username-duplicate`,
  // GET_DETECT_EMAIL_DUPLICATE_URL: `auth/get-detect-email-duplicate`,
  // GET_DETECT_PHONE_DUPLICATE_URL: `auth/get-detect-phone-duplicate`,
  // GET_PREFIX_ALL_URL: `auth/get-prefix-all`,
  // GET_LOGIN_DETECT_URL: `auth/get-login-detect`,
  // GET_USER_BY_UID_URL: `auth/get-user-by-uid`,
  // GET_EMAIL_BY_USERNAME_URL: `auth/get-email-by-username`,
  // GET_EMAIL_PASSWORD_IS_ON_DB_URL: `auth/get-email-password-is-on-db`,

  // INSERT_USERS_URL: `user/insert-users`,

  // UPDATE_USERS: `user/update-users`,

  // // ------------------------ PAYMENT ------------------------------------------------------------
  // UPDATE_PAYMENT_ACCOUNT_BY_ID_URL: `payment/update-payment-account-by-id`,

  // // ------------------------ ERP ----------------------------------------------------------------
  // INSERT_PAYMENT_ORDER_URL: `payment/insert-payment-order`,

  // UPDATE_PAYMENT_ORDER_COURSE_BY_ID_URL: `payment/update-payment-order-by-id`,
  // UPDATE_PREDICTION_BY_ID_URL: `prediction/update-prediction-by-id`,

  // // ------------------------ COURSE -------------------------------------------------------------
  // GET_COURSE_ALL_URL: `course/get-course-all`,
  // GET_COURSE_WAIT_INSPECT_URL: `course/get-course-wait-inspect`,
  // GET_COURSE_PURCHASED_AND_NOT_EXPIRED_URL: `course/get-course-purchased-and-not-expired`,

  // // ------------------------ CLASSROOM ----------------------------------------------------------
  // GET_CLASSROOM_BY_ID_URL: `classroom/get-classroom-by-id`,
  // GET_COURSE_BY_ID_URL: `classroom/get-course-by-id`,
  // GET_VIDEO_COURSE_BY_ID_URL: `classroom/get-video-course-by-id`,

  // // ------------------------ MANDALA ------------------------------------------------------------
  // GET_MANDALA_BY_ID_URL: `mandala/get-mandala-by-id`,

  // // ------------------------ KHUN HOR -----------------------------------------------------------
  // GET_KHUN_HOR_BY_ID_URL: `khun_hor/get-khun-hor-by-id`,

  // // ------------------------ PREDICTION ---------------------------------------------------------
  // GET_PREDICTION_ALL_URL: `prediction/get-prediction-all`,
  // GET_PREDICTION_WAIT_INSPECT_URL: `prediction/get-prediction-wait-inspect`,
  // GET_PREDICTION_PURCHASED_AND_NOT_EXPIRED_URL: `prediction/get-prediction-purchased-and-not-expired`,

  // GET_FRTUNE_TELLER_QUESTIONS_MASTER_All:  `prediction/get-fortune-teller-questions-master-all`,
  // GET_FRTUNE_TELLER_QUESTIONS_All:  `prediction/get-fortune-teller-questions-all`,
  // GET_FRTUNE_TELLER_QUESTIONS_BY_ID:  `prediction/get-fortune-teller-questions-by-id`,

  // // ------------------------ PREDICTION ROOM ----------------------------------------------------
  // GET_PREDICTION_ROOM_BY_ID_URL: `prediction_room/get-prediction-room-by-id`,
  // GET_PREDICTION_BY_ID_URL: `prediction_room/get-prediction-by-id`,

  // // ------------------------ PREDICTION OPEN ----------------------------------------------------
  // GET_BIRTHDAY_USER_BY_ID_URL: `auth/get-birthday-user-by-id`,

  // // ------------------------ EBOOK --------------------------------------------------------------
  // GET_EBOOK_ALL_URL: `ebook/get-ebook-all`,

  // // ------------------------ EXAM ---------------------------------------------------------------
  // GET_EXAM_ROOM_BY_ID_URL: `exam/get-exam-room-by-id`,
  // GET_EXAM_ROOM_ALL_URL: `exam/get-exam-room-all`,
  // GET_PERMISSION_EXAM_BY_ID_URL: `exam/get-permission-exam-by-id`,
  // GET_ANSWER_LIST_BY_ID_URL: `exam/get-answer-list-by-id`,
  // UPDATE_ANSWER_LIST_BY_ID_URL: `exam/update-answer-list-by-id`,
}
