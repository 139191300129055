import { httpClient } from "../../../utils/HttpClient"

const getExamStatusAllFetch = async (value) => {
    try {
        const result = await httpClient.get(`exam/get-exam-status-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getExamRoomStatusAllFetch = async (value) => {
    try {
        const result = await httpClient.get(`exam/get-exam-room-status-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getCourseTypeAllFetch = async (value) => {
    try {
        const result = await httpClient.get(`exam/get-course-type-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getExamBySearchFetch = async (value) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`exam/get-exam-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getQuestionListByIdFetch = async (examId) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(examId).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`exam/get-question-list-by-id?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getExamInspectLogByIdFetch = async (examRoomId) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(examRoomId).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`exam/get-exam-inspect-log-by-id?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const insertExamRoomFetch = async (value) => {
    try {
        const result = await httpClient.post(`exam/insert-exam-room`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const insertQuestionListFetch = async (value) => {
    try {
        const result = await httpClient.post(`exam/insert-question-list`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const insertAnswerListFetch = async (value) => {
    try {
        const result = await httpClient.post(`exam/insert-answer-list`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const insertExamInspectLogFetch = async (value) => {
    try {
        const result = await httpClient.post(`exam/insert-exam-inspect-log`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateExamRoomByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`exam/update-exam-room-by-id`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateExamByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`exam/update-exam-by-id`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateQuestionListByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`exam/update-question-list-by-id`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateAnswerScoreByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`exam/update-answer-score-by-id`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const deleteQuestionListByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`exam/delete-question-list-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteAnswerListByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`exam/delete-answer-list-by-id/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getExamStatusAllFetch,
    getExamRoomStatusAllFetch,
    getCourseTypeAllFetch,
    getExamBySearchFetch,
    getQuestionListByIdFetch,
    getExamInspectLogByIdFetch,

    // insert
    insertExamRoomFetch,
    insertQuestionListFetch,
    insertAnswerListFetch,
    insertExamInspectLogFetch,

    // update
    updateExamRoomByIdFetch,
    updateExamByIdFetch,
    updateQuestionListByIdFetch,
    updateAnswerScoreByIdFetch,

    // delete
    deleteQuestionListByIdFetch,
    deleteAnswerListByIdFetch
}
