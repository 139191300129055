import { httpClient } from "../../../utils/HttpClient"

const getPageContactFetch = async () => {
    try {
        const result = await httpClient.get("contact/get-page-contact")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePageContactByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("contact/update-page-contact-by-id", { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPageContactFetch,

    // insert

    // update
    updatePageContactByIdFetch,

    // delete
}