import { httpClient } from "../../../../../utils/HttpClient";

const getReviewBySearchFetch = async (value) => {
    // Done
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.get(`review/get-review-by-search?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {
        // status 404
        return null
    }
}

const insertReviewFetch = async (value) => {
    // Done
    try {
        const result = await httpClient.post(`review/insert-review`, { value })
        return result.data
    } catch (err) {
        // status 404
        return null
    }
}

const updateReviewByIdFetch = async (value) => {
    // Done
    try {
        const result = await httpClient.put(`review/update-review-by-id`, { value })
        return result.data
    } catch (err) {
        // status 404
        return null
    }
}

const deleteReviewByIdFetch = async (id) => {
    // Done
    try {
        const result = await httpClient.delete(`review/delete-review-by-id` + `/${id}`)
        return result.data
    } catch (err) {
        // status 404
        return null
    }
}

export {
    // get
    getReviewBySearchFetch,

    // insert
    insertReviewFetch,

    // update
    updateReviewByIdFetch,

    // delete
    deleteReviewByIdFetch
}