/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Upload,
    InputNumber,
    Select
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import {
    deleteCourseByIdFetch,
    deleteVideoCourseByIdFetch,
    getCourseBySearchFetch,
    getVideoCourseBySearchFetch,
    insertCourseFetch,
    insertVideoCourseFetch,
    updateCourseByIdFetch,
    updateVideoCourseByIdFetch
} from './API'
import Video from '../../../../common/components/video'
import { getCourseTypeAllFetch } from '../../../exam/API'
import { getCourseAllFetch } from '../../../course/API'

const { Option } = Select
const { RangePicker } = DatePicker

const columnsCourse = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ภาพปก',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    <img
                        style={{ borderRadius: 8, maxHeight: 70 }}
                        src={`${serverUrl}/${record.imgCoverUrl}`}
                    />
                </>
            )
        }
    },
    {
        title: 'ชื่อคอร์ส',
        dataIndex: 'title',
        width: "30%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "15%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const columnsVideoCourse = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    // {
    //     title: 'ภาพปกวิดีโอ',
    //     width: "15%",
    //     render: (text, record) => {
    //         return (
    //             <>
    //                 {console.log(record)}
    //                 <img
    //                     style={{ borderRadius: 8, padding: 6, maxHeight: 60, border: "1px solid #EEEEEE" }}
    //                     src={`${serverUrl}/${record.imgCoverUrl}`}
    //                 />
    //             </>
    //         )
    //     }
    // },
    {
        title: 'ชื่อวิดีโอคอร์ส',
        dataIndex: 'title',
        width: "55%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "15%",
    },
    // {
    //     title: 'วันที่เเก้ไข',
    //     dataIndex: 'updatedAt',
    //     width: "15%",
    // },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function CourseManage() {

    const [courseList, setCourseList] = useState([])
    const [videoCourseList, setVideoCourseList] = useState([])

    const [courseLoading, setCourseLoading] = useState(true)
    const [videoCourseLoading, setVideoCourseLoading] = useState(false)

    const [formCourse] = Form.useForm()
    const [formVideoCourse] = Form.useForm()
    const [formSearch] = Form.useForm()

    const audioReboundRef = useRef()

    const [course, setCourse] = useState()
    const [courseType, setCourseType] = useState()

    const [imgCoverCourseURL, setImgCoverCourseURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [imgCoverContentCourseURL, setImgContentCoverCourseURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [vdoCourseURL, setVdoCourseURL] = useState({
        loading: false,
        videoUrl: null
    })

    const [vdoContentCourseURL, setVdoContentCourseURL] = useState({
        loading: false,
        videoUrl: null
    })

    // const [subTitle, setSubTitle] = useState()
    const [detail, setDetail] = useState()
    const [promotion, setPromotion] = useState()

    // const subTitleRef = useRef()
    const detailRef = useRef()
    const promotionRef = useRef()

    const [courseIdSelected, setCourseIdSelected] = useState(null)

    const [pageCourseCurrent, setPageCourseCurrent] = useState(1)
    const [pageVideoCourseCurrent, setPageVideoCourseCurrent] = useState(1)

    const [videoContentCourseModal, setVideoContentCourseModal] = useState({
        isShow: false,
        title: null
    })

    const [operation, setOperation] = useState({
        type: null,
        isOpen: false
    })
    
    const optionCoverCourseImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "course/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgCoverCourseURL({
                        imageUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setImgCoverCourseURL({
                    loading: true,
                    imageUrl: imgCoverCourseURL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionCourseVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "course/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setVdoCourseURL({
                        videoUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setVdoCourseURL({
                    loading: true,
                    videoUrl: vdoCourseURL.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionCoverContentCourseImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "course/content/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgContentCoverCourseURL({
                        imageUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setImgContentCoverCourseURL({
                    loading: true,
                    imageUrl: imgCoverContentCourseURL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionContentCourseVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "witte-bangkok",
            folder: "course/content/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setVdoContentCourseURL({
                        videoUrl: info.file.response.url,
                        loading: false,
                    })
                }
            } else {
                setVdoContentCourseURL({
                    loading: true,
                    videoUrl: vdoContentCourseURL.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }
    
    const getCourseBySearch = async (title, startedDate, endDate) => {
        setCourseLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getCourseBySearchFetch : ", objSearch)
        const result = await getCourseBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                imgCoverUrl: val.imgCoverUrl,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                audioReboundRef?.current?.play()
                                formCourse.setFieldsValue({
                                    courseId: val.courseId,
                                    title: val.title,
                                    subTitle: val.subTitle,
                                    price: val.price,
                                    certificateForCourseId: val.certificateForCourseId,
                                    courseTypeId: val.courseTypeId,
                                    isCoverTypeDisplay: val.isCoverTypeDisplay
                                })

                                await getVideoCourseBySearch(val.courseId, null, null, null)

                                setImgCoverCourseURL({
                                    loading: false,
                                    imageUrl: val.imgCoverUrl
                                })

                                setVdoCourseURL({
                                    loading: false,
                                    videoUrl: val.videoUrl
                                })

                                // setSubTitle(val.subTitle ?? "")
                                setDetail(val.detail ?? "")
                                setPromotion(val.promotion ?? "")

                                // subTitleRef.current = val.subTitle ?? ""
                                detailRef.current = val.detail ?? ""
                                promotionRef.current = val.promotion ?? ""

                                setCourseIdSelected(val.courseId)
                                setOperation({ type: "edit", isOpen: true })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleCourseDelete(val.courseId)

                                // reload
                                await getCourseBySearch(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef?.current?.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setCourseList(tempList)

        setCourseLoading(false)
    }

    const getVideoCourseBySearch = async (courseId, title, startedDate, endDate) => {
        setVideoCourseLoading(true)

        let objSearch = {
            courseId, title, startedDate, endDate
        }
        // console.log("getVideoCourseBySearchFetch-1 : ", objSearch)
        const result = await getVideoCourseBySearchFetch(objSearch)
        // console.log("getVideoCourseBySearchFetch-2 : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                // imgCoverUrl: val.imgCoverUrl,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                audioReboundRef?.current?.play()
                                formVideoCourse.setFieldsValue({
                                    courseVideoId: val.courseVideoId,
                                    title: val.title,
                                    // detail: val.detail,
                                })

                                setImgContentCoverCourseURL({
                                    loading: false,
                                    imageUrl: val.imgCoverUrl
                                })

                                setVdoContentCourseURL({
                                    loading: false,
                                    videoUrl: val.videoUrl
                                })

                                setVideoContentCourseModal({
                                    isShow: true,
                                    title: "edit"
                                })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleVideoCourseDelete(val.courseVideoId)

                                // reload
                                await getVideoCourseBySearch(null, null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef.current.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setVideoCourseList(tempList)

        setVideoCourseLoading(false)
    }

    const onCourseFinish = async (values) => {
        let obj = {
            courseId: values.courseId ? values.courseId : null,
            title: values.title ? values.title : null,
            price: values.price ? values.price : null,
            subTitle: values.subTitle ? values.subTitle : null,
            detail: detailRef.current ? detailRef.current : null,
            promotion: promotionRef.current ? promotionRef.current : null,
            imgCoverUrl: imgCoverCourseURL.imageUrl ? imgCoverCourseURL.imageUrl : null,
            videoUrl: vdoCourseURL.videoUrl ? vdoCourseURL.videoUrl : null,
            certificateForCourseId: values.certificateForCourseId ? values.certificateForCourseId : null,
            courseTypeId: values.courseTypeId ? values.courseTypeId : null,
            isCoverTypeDisplay: values.isCoverTypeDisplay ? values.isCoverTypeDisplay : null,
        }
        // console.log("onFinish : ", obj)

        if (operation.type === "add") {
            const result = await insertCourseFetch(obj)
            if (result.isSuccess) {
                // console.log("result?.formData?.courseId : ", result?.formData?.courseId)
                setCourseIdSelected(result?.formData?.courseId)
                Notification('success', 'สร้างสำเร็จ')
                setOperation({ type: null, isOpen: false })
                window.scrollTo(0, 0)
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (operation.type === "edit") {
            const result = await updateCourseByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'บันทึกสำเร็จ')
                setOperation({ type: null, isOpen: false })
                window.scrollTo(0, 0)
            } else {
                Notification('error', 'ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getCourseBySearch(null, null, null)

        // set default
        if (operation.type !== "add") {
            setFormCourseDefault()
        }
    }

    const onVideoCourseFinish = async (values) => {
        let obj = {
            courseId: courseIdSelected,
            courseVideoId: values.courseVideoId ? values.courseVideoId : null,
            title: values.title ? values.title : null,
            videoUrl: vdoContentCourseURL.videoUrl ? vdoContentCourseURL.videoUrl : null,
            imgCoverUrl: imgCoverContentCourseURL.imageUrl ? imgCoverContentCourseURL.imageUrl : null,
        }
        // console.log("onFinish : ", obj)

        if (videoContentCourseModal.title === "add") {
            const result = await insertVideoCourseFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (videoContentCourseModal.title === "edit") {
            const result = await updateVideoCourseByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getVideoCourseBySearch(null, null, null)

        // set default
        setFormVideoCourseDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getCourseBySearch(title, startedDate, endDate)
    }

    const handleCourseDelete = async (id) => {
        const result = await deleteCourseByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleVideoCourseDelete = async (id) => {
        const result = await deleteVideoCourseByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onCoursePagine = (n) => {
        setPageCourseCurrent(n.current)
    }

    const onVideoCoursePagine = (n) => {
        setPageVideoCourseCurrent(n.current)
    }

    const setFormCourseDefault = () => {
        formCourse.resetFields()

        setImgCoverCourseURL({
            loading: false,
            imageUrl: null
        })

        setVdoCourseURL({
            loading: false,
            videoUrl: null
        })

        // setSubTitle(null)
        setDetail(null)
        setPromotion(null)

        // subTitleRef.current = null
        detailRef.current = null
        promotionRef.current = null

        setCourseIdSelected(null)
    }

    const setFormVideoCourseDefault = () => {
        formVideoCourse.resetFields()

        setImgContentCoverCourseURL({
            loading: false,
            imageUrl: null
        })

        setVideoContentCourseModal({
            isShow: false,
            title: null
        })
    }

    const getCourseTypeAll = async () => {
        const result = await getCourseTypeAllFetch()
        // console.log("getCourseTypeAllFetch : ", result)
        setCourseType(result)
    }

    const getCourseAll = async () => {
        const result = await getCourseAllFetch()
        // console.log("getCourseAllFetch : ", result)
        setCourse(result)
    }

    const getBaseApi = async () => {
        await getCourseBySearch(null, null, null)
        await getCourseTypeAll()
        await getCourseAll()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("courseIdSelected : ", courseIdSelected)

    return (
        <Row>
            <Col span={8}>
                <label>จัดการคอร์ส</label>
            </Col>
            <Col span={16}>
                {!operation.isOpen ?
                    <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item
                                    label="ชื่อคอร์ส"
                                    name="title"
                                    style={{ width: '100%' }}
                                >
                                    <Input />
                                </Form.Item>
                            </div>

                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item
                                    label="ห้วงวันที่สร้าง" name="dateRange"
                                    style={{ width: '100%' }}
                                >
                                    <RangePicker />
                                </Form.Item>
                            </div>

                            <div style={{ paddingLeft: 10, marginTop: 3 }}>
                                <Button
                                    style={{ float: 'right', width: 70 }}
                                    type="primary"
                                    onClick={() => {
                                        audioReboundRef?.current?.play()
                                        formSearch.submit()
                                    }}
                                >
                                    ค้นหา
                                </Button>
                            </div>
                        </div>
                    </Form>
                    : []
                }
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                    <div style={{ paddingLeft: 12 }}>
                        <Button
                            type={!operation.isOpen ? "primary" : "default"}
                            style={{ float: 'right' }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                setFormCourseDefault()
                                setFormVideoCourseDefault()

                                setOperation({ type: "add", isOpen: !operation.isOpen })
                            }}
                        >
                            {!operation.isOpen ? "เพิ่มคอร์ส" : "ยกเลิกเพิ่มคอร์ส"}
                        </Button>
                    </div>
                    {"   "}

                    {operation.isOpen ?
                        <div style={{ paddingLeft: 12 }}>
                            <Button
                                type="primary"
                                disabled={!courseIdSelected}
                                style={{ float: 'right' }}
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    setFormVideoCourseDefault()
                                    setVideoContentCourseModal({
                                        isShow: true,
                                        title: "add"
                                    })
                                }}
                            >
                                เพิ่มวิดีโอคอร์ส
                            </Button>
                        </div>
                        : []
                    }
                </div>
            </Col>

            {operation.isOpen ?
                <Col span={24}>
                    <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 8 }}>
                        <Form
                            layout="vertical"
                            form={formCourse}
                            onFinish={onCourseFinish}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12} xl={14}>
                                    <Row gutter={[16, 0]}>
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item name="courseId" style={{ display: "none" }}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name="title"
                                                label="ชื่อคอร์ส"
                                                rules={[{ required: true, message: 'กรุณากรอกชื่อคอร์ส' }]}
                                            >
                                                <Input.TextArea
                                                    style={{ width: "100%" }}
                                                    autoSize={{ minRows: 1, maxRows: 5 }}
                                                    maxLength={200}
                                                    showCount
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                name="price"
                                                label="ราคาคอร์ส"
                                                rules={[{ required: true, message: 'กรุณากรอกราคาคอร์ส' }]}
                                            >
                                                <InputNumber style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="subTitle"
                                                label="รายละเอียดย่อย"
                                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดย่อย' }]}
                                            >
                                                <Input.TextArea
                                                    style={{ width: "100%" }}
                                                    autoSize={{ minRows: 2, maxRows: 10 }}
                                                    maxLength={500}
                                                    showCount
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="detail"
                                                label="รายละเอียดคอร์ส"
                                            >
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'heading', '|',
                                                                'fontfamily', 'fontsize', '|',
                                                                'bold', 'italic', 'underline', '|',
                                                                'alignment', '|',
                                                                'fontColor', 'fontBackgroundColor', '|',
                                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                                'code', 'codeBlock', '|',
                                                                'undo', 'redo'
                                                            ],
                                                            removeButtons: 'Subscript,Superscript',
                                                            height: 350,
                                                        },
                                                    }}
                                                    data={detail}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData()
                                                        detailRef.current = data
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="promotion"
                                                label="โปรโมชั่น"
                                            >
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'heading', '|',
                                                                'fontfamily', 'fontsize', '|',
                                                                'bold', 'italic', 'underline', '|',
                                                                'alignment', '|',
                                                                'fontColor', 'fontBackgroundColor', '|',
                                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                                'code', 'codeBlock', '|',
                                                                'undo', 'redo'
                                                            ],
                                                            removeButtons: 'Subscript,Superscript',
                                                            height: 350,
                                                        },
                                                    }}
                                                    data={promotion}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData()
                                                        promotionRef.current = data
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={24} md={12} xl={10}>
                                    <Row>
                                        <Col span={24}>
                                            <Table
                                                loading={videoCourseLoading}
                                                columns={columnsVideoCourse}
                                                dataSource={videoCourseList}
                                                pagination={{
                                                    current: pageVideoCourseCurrent,
                                                    pageSize: 10,
                                                    total: videoCourseList?.length
                                                }}
                                                onChange={(n) => onVideoCoursePagine(n)}
                                            >
                                            </Table>
                                            {!courseIdSelected ? <label style={{ color: "red" }}>* กรุณาสร้างคอร์ส ก่อนที่จะเพิ่มวิดีโอคอร์ส</label> : []}
                                        </Col>

                                        <Col xs={24} md={24} xl={24} style={{ paddingTop: 22 }}>
                                            <Form.Item
                                                name="courseTypeId"
                                                label="ประเภทคอร์ส"
                                                rules={[{ required: true, message: 'กรุณาเลือกประเภทคอร์ส' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    optionFilterProp="children"
                                                    allowClear
                                                >
                                                    {courseType?.map((val, index) =>
                                                        <Option key={index} value={val.courseTypeId}>{val.name}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="certificateForCourseId"
                                                label="ใบประกาศนียบัตรของคอร์ส"
                                            // rules={[{ required: true, message: 'กรุณาเลือกคอร์ส' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    optionFilterProp="children"
                                                    allowClear
                                                >
                                                    {course?.map((val, index) =>
                                                        <Option key={index} value={val.courseId}>{val.title}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ paddingBottom: 6 }}>รูปภาพคอร์ส</label>
                                        {imgCoverCourseURL?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #C4C4C4" }}
                                                src={`${serverUrl}/${imgCoverCourseURL.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionCoverCourseImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imgCoverCourseURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    onClick={() => audioReboundRef?.current?.play()}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ paddingBottom: 6 }}>วิดีโอคอร์ส</label>
                                        {vdoCourseURL?.videoUrl ?
                                            <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                <Video
                                                    url={vdoCourseURL.videoUrl}
                                                    title={""}
                                                    height={"100%"}
                                                    width={"100%"}
                                                />
                                            </div>
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                src={`./assets/images/default/df-vdo.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionCourseVideo}
                                                accept='.mp4'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={vdoCourseURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    onClick={() => audioReboundRef?.current?.play()}
                                                >อัพโหลดวิดีโอ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="isCoverTypeDisplay"
                                        label="เเสดงปกด้วยรูปภาพหรือวิดีโอ"
                                        rules={[{ required: true, message: 'กรุณาเลือกประเภทการเเสดง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            <Option value={1}>รูปภาพ</Option>
                                            <Option value={2}>วิดีโอ</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                        <div style={{ paddingLeft: 12 }}>
                                            <Button
                                                type="primary"
                                                disabled={operation.type === "add" && courseIdSelected ? true : false}
                                                onClick={() => {
                                                    audioReboundRef?.current?.play()
                                                    formCourse.submit()
                                                }}
                                            >
                                                {operation.type === "add" ? "สร้างคอร์ส" : []}
                                                {operation.type === "edit" ? "บันทึก" : []}
                                            </Button>
                                        </div>

                                        <div style={{ paddingLeft: 12 }}>
                                            {operation.type === "add" && courseIdSelected ?
                                                <Button
                                                    type="default"
                                                    onClick={() => {
                                                        audioReboundRef?.current?.play()
                                                        setFormCourseDefault()
                                                    }}
                                                >
                                                    ปิดการเเก้ไข
                                                </Button>
                                                : []
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                : []
            }

            {
                !operation.isOpen ?
                    <Col span={24}>
                        <Table
                            loading={courseLoading}
                            columns={columnsCourse}
                            dataSource={courseList}
                            pagination={{
                                current: pageCourseCurrent,
                                pageSize: 10,
                                total: courseList?.length
                            }}
                            onChange={(n) => onCoursePagine(n)}
                        >
                        </Table>
                    </Col>
                    : []
            }

            <Modal
                title={<strong><label className="topic-color-bold">{videoContentCourseModal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={videoContentCourseModal.isShow}
                onCancel={() => {

                    // default
                    setFormVideoCourseDefault()
                }}
                width={700}
                onOk={() => {
                    formVideoCourse.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formVideoCourse}
                    onFinish={onVideoCourseFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="courseVideoId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="ชื่อวิดีโอคอร์ส"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อวิดีโอคอร์ส' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>ภาพปกวิดีโอคอร์ส</label>
                                {imgCoverContentCourseURL?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imgCoverContentCourseURL.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: 207, borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionCoverContentCourseImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imgCoverContentCourseURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            onClick={() => audioReboundRef?.current?.play()}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>วิดีโอคอร์ส</label>
                                {vdoContentCourseURL?.videoUrl ?
                                    <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                        <Video
                                            url={vdoContentCourseURL.videoUrl}
                                            title={""}
                                            height={"100%"}
                                            width={"100%"}
                                        />
                                    </div>
                                    :
                                    <img
                                        style={{ width: "100%", borderRadius: 8 }}
                                        src={`./assets/images/default/df-vdo.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionContentCourseVideo}
                                        accept='.mp4'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={vdoContentCourseURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            onClick={() => audioReboundRef?.current?.play()}
                                        >อัพโหลดวิดีโอ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </Row >
    )
}
